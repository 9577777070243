import { useState } from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

import SLabel from '@setvi/shared/components/sui/slabel';

import { Container, CustomField, FieldContainer } from './style';

export interface PasswordProps {
  name: string;
  value: string;
  label?: string;
  error?: boolean;
  maxLength?: number;
  disabled?: boolean;
  required?: boolean;
  labelClass?: string;
  helperText?: string;
  inputClass?: string;
  placeholder: string;
  defaultValue?: string;
  onChange: (value: string) => void;
}

enum FieldType {
  TEXT = 'text',
  PASSWORD = 'password'
}

const Password = ({
  name,
  value,
  label,
  error,
  maxLength,
  disabled,
  required,
  labelClass,
  helperText,
  inputClass,
  placeholder,
  defaultValue,
  onChange
}: PasswordProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Container>
      {label ? (
        <SLabel
          variant="body2"
          title={label}
          className={labelClass}
          required={required}
        />
      ) : null}

      <FieldContainer>
        <CustomField
          fullWidth
          name={name}
          value={value}
          error={error}
          autoFocus={false}
          type={showPassword ? FieldType.TEXT : FieldType.PASSWORD}
          autoComplete="new-item"
          variant="outlined"
          disabled={disabled}
          className={inputClass}
          helperText={helperText}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={e => onChange(e.target.value)}
          id={`standard-multiline-flexible-${name}`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  onClick={() => setShowPassword(prev => !prev)}
                  style={{
                    padding: 0,
                    margin: 0
                  }}>
                  {showPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={
            maxLength && {
              maxLength: maxLength + 1
            }
          }
        />
      </FieldContainer>
    </Container>
  );
};

export default Password;
