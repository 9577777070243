// ** Overrides Imports
import { Theme } from '@material-ui/core';
import MuiInput from './input';
import MuiButton from './button';
import MuiCheckbox from './checkbox';
import MuiTooltip from './tooltip';
import MuiAlert from './alert';

const Overrides = (theme: Theme) => {
  const input = MuiInput(theme);
  const button = MuiButton(theme);
  const checkbox = MuiCheckbox(theme);
  const tooltip = MuiTooltip(theme);
  const alert = MuiAlert(theme);

  return Object.assign(input, button, checkbox, tooltip, alert);
};

export default Overrides;
