import { useEffect, useState } from 'react';

import { SelectProps } from '../index';
import { SOption } from '../../types';

// TODO: Delete
export const useSingleSelect = ({
  options = [],
  defaultOption,

  onChange
}: Partial<SelectProps>) => {
  const [data, setData] = useState(options);
  const [anchor, setAnchor] = useState(null);
  const [selected, setSelected] = useState<SOption | null>(
    defaultOption || null
  );

  const handleSelect = (option: SOption) => {
    onChange(option);
    setSelected(option);
  };

  const handleSearch = (value: string) => {
    if (value)
      return setData(
        options.filter(t =>
          t.value.toString().toLowerCase().includes(value.toLowerCase())
        )
      );
    if (!value) return setData(options);
  };

  // This abomination should be deleted with entire component
  useEffect(() => {
    if (defaultOption) setSelected(defaultOption);
  }, [defaultOption]);

  return {
    data,
    anchor,
    selected,

    setData,
    setAnchor,
    setSelected,
    handleSelect,
    handleSearch
  };
};
