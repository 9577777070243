import { useState, useCallback, useMemo, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import ClearIcon from '@material-ui/icons/Clear';

import { ExpandIcon } from '@setvi/shared/components/sicons';
import { RESOURCE_UPLOAD_STATUS } from '@setvi/shared/enums';
import { UploadedResource } from '@setvi/shared/interfaces';

import {
  AdminCategoryQueryKey,
  AdminResroucesQueryKey,
  adminResourceUploadFinishedMutation,
  getAdminResourcesProcessStatusesQuery
} from 'Services';
import { useSubscribedMutation } from 'Hooks/React-Query';
import { ResourceCategoryClassName } from 'pages/admin/enum';
import { useAppContext } from 'Providers/AppProvider/AppContext';

import { UploadResourcePanelProps } from '../context';

export interface IFile extends File {
  mimetype?: string | number;
  filename?: string;
}
export interface UploadResourceProps {
  id: number;
  file: IFile;
  status: RESOURCE_UPLOAD_STATUS;
  error?: string;
  filestackId?: string;
  unzipedTotal?: number;
  unzipedProcesed?: number;
  unzippedResources?: UploadedResource[];
}

export const useUpload = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { user } = useAppContext();

  const [showPanel, setShowPanel] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [offset, setOffset] = useState(0);

  const [uploadedResources, setUploadedResources] = useState<
    UploadResourceProps[]
  >([]);

  const activeResources = useMemo(
    () =>
      uploadedResources?.filter(u =>
        [
          RESOURCE_UPLOAD_STATUS.UPLOADING,
          RESOURCE_UPLOAD_STATUS.PROCESSING,
          RESOURCE_UPLOAD_STATUS.UNPROCESSED,
          RESOURCE_UPLOAD_STATUS.BULK_UNPACKED,
          RESOURCE_UPLOAD_STATUS.REPLACE_UNPROCESSED,
          RESOURCE_UPLOAD_STATUS.REPLACING,
          RESOURCE_UPLOAD_STATUS.REPLACE_UPLOADING
        ].includes(u?.status)
      ),
    [uploadedResources]
  );

  queryClient.invalidateQueries({
    queryKey: [
      AdminCategoryQueryKey.ADMIN_CATEGORIES,
      ResourceCategoryClassName.Company,
      0
    ]
  });

  const queryVariables = useMemo(
    () => activeResources?.map(({ id }) => id),
    [activeResources]
  );

  const triggerAPI = useMemo(() => {
    if (!activeResources?.length) return false;

    const processed = uploadedResources?.filter(res =>
      [
        RESOURCE_UPLOAD_STATUS.ERROR,
        RESOURCE_UPLOAD_STATUS.PROCESSED,
        RESOURCE_UPLOAD_STATUS.BULK_PROCESSED
      ].includes(res?.status)
    )?.length;

    if (processed === uploadedResources.length) return false;

    return true;
  }, [activeResources, uploadedResources]);

  const { data, isFetching } = useQuery({
    ...getAdminResourcesProcessStatusesQuery(queryVariables),
    enabled: triggerAPI,
    refetchInterval: 4000
  });

  // Helper function to check if the breadcrumb combination is unique
  const isUniqueBreadcrumbCombination = (
    arr: UploadedResource[],
    breadcrumb: object
  ) =>
    !arr.some(
      item =>
        JSON.stringify(item.ResourceCategoryBreadcrumbs) ===
        JSON.stringify(breadcrumb)
    );

  // With this we update the status of the resources and check if it has an error
  useEffect(() => {
    if (!data || isFetching) return;

    setUploadedResources(prev =>
      prev?.map(resource => {
        const sameResource = data.find(
          ({ Id }: { Id: number }) => Id === resource.id
        );

        let unzipedProcesed = 0;
        let unzipedCompleted;
        let oldUnzipedCompleted;
        if (sameResource?.UnzippedResources?.length) {
          unzipedProcesed = sameResource?.UnzippedResources?.filter(
            (res: UploadedResource) =>
              [
                RESOURCE_UPLOAD_STATUS.PROCESSING,
                RESOURCE_UPLOAD_STATUS.PROCESSED
              ].includes(res?.Processing)
          )?.length;

          unzipedCompleted = sameResource?.UnzippedResources?.filter(
            (res: UploadedResource) =>
              res?.Processing === RESOURCE_UPLOAD_STATUS.PROCESSED
          );

          oldUnzipedCompleted = resource?.unzippedResources?.filter(
            (res: UploadedResource) =>
              res?.Processing === RESOURCE_UPLOAD_STATUS.PROCESSED
          );
        }

        const unzipedTotal = sameResource?.UnzippedResources?.length || 0;
        const status =
          sameResource?.Processing === RESOURCE_UPLOAD_STATUS.BULK_UNPACKED &&
          unzipedCompleted?.length === unzipedTotal
            ? RESOURCE_UPLOAD_STATUS.PROCESSED
            : sameResource?.Processing || resource?.status;

        if (
          (sameResource?.Processing === RESOURCE_UPLOAD_STATUS.PROCESSED &&
            sameResource?.Processing !== resource?.status) ||
          unzipedCompleted?.length !== oldUnzipedCompleted?.length
        ) {
          queryClient.invalidateQueries({
            queryKey: [AdminResroucesQueryKey.ADMIN_RESOURCES]
          });

          // Here we get zip file breadcrumbs and compare them with the unzipped resources
          const zipCategories =
            sameResource?.ResourceCategoryBreadcrumbs?.length;
          const findResourcesInsideNewCategory =
            sameResource?.UnzippedResources?.filter(
              (res: UploadedResource) =>
                res?.ResourceCategoryBreadcrumbs?.length !== zipCategories
            );

          // Filter the array to keep only elements with unique breadcrumb combinations
          const filteredArray = findResourcesInsideNewCategory?.filter(
            (item, index, array) =>
              isUniqueBreadcrumbCombination(
                array.slice(0, index),
                item.ResourceCategoryBreadcrumbs
              )
          );

          // We compare if current resource uploaded is inside a new category
          const oldUnziped = oldUnzipedCompleted;
          const currentUnzipedResource = unzipedCompleted?.filter(
            item => !oldUnziped.includes(item)
          )?.[0];

          // If the resource is inside a new category we invalidate the query for categories
          if (filteredArray.includes(currentUnzipedResource))
            queryClient.invalidateQueries({
              queryKey: [AdminCategoryQueryKey.ADMIN_CATEGORIES]
            });

          enqueueSnackbar(`Resource upload completed.`, {
            variant: 'success'
          });
        }

        return {
          ...resource,
          error:
            sameResource?.ErrorExceptionMessage ||
            sameResource?.ErrorMessage ||
            resource?.error,
          status,
          unzipedTotal,
          unzipedProcesed,
          unzippedResources: sameResource?.UnzippedResources || []
        };
      })
    );
  }, [data, enqueueSnackbar, isFetching, queryClient]);

  const { mutateAsync: uploadFinished } = useSubscribedMutation(
    adminResourceUploadFinishedMutation(),
    [AdminResroucesQueryKey.ADMIN_RESOURCES_PROCESS_STATUS]
  );

  const open = useCallback(({ resources }: UploadResourcePanelProps) => {
    setShowPanel(true);
    if (resources) setUploadedResources(prev => [...prev, ...resources]);
  }, []);

  const close = () => {
    setShowPanel(false);
    setExpanded(false);
    setUploadedResources([]);
  };

  const minmizedElements = [
    {
      key: '1',
      icon: (
        <ExpandIcon
          style={{
            transform: 'scale(0.6)'
          }}
        />
      ),
      onClick: () => setExpanded(true)
    },
    {
      key: '2',
      icon: <ClearIcon htmlColor="#fff" />,
      onClick: () => close()
    }
  ];

  const headerElements = [
    {
      key: '1',
      icon: <RemoveRoundedIcon />,
      onClick: () => setExpanded(false)
    },
    {
      key: '2',
      icon: <ClearIcon />,
      onClick: () => close()
    }
  ];

  const onUploadFinished = async (
    resourceId: number,
    filestackId: string,
    filename: string
  ) => {
    setUploadedResources(prev => {
      const updatedData = prev.map(item => {
        if (item.id === resourceId) {
          return {
            ...item,
            status: RESOURCE_UPLOAD_STATUS.UPLOADING
          };
        }
        return item;
      });
      return updatedData;
    });

    await uploadFinished({
      resources: [
        {
          resourceId,
          filestackId,
          material: filename || ''
        }
      ]
    });
  };

  const onUploadCancel = (resourceId: number) => {
    setUploadedResources(prev => {
      const updatedData = prev.map(item => {
        if (item.id === resourceId) {
          return {
            ...item,
            status: RESOURCE_UPLOAD_STATUS.FILESTACK_ERROR,
            error: 'Upload canceled by user.'
          };
        }
        return item;
      });
      return updatedData;
    });
  };

  const title = useMemo(() => {
    const total = uploadedResources?.length || 0;
    const completed = uploadedResources?.filter(res =>
      [
        RESOURCE_UPLOAD_STATUS.PROCESSED,
        RESOURCE_UPLOAD_STATUS.BULK_PROCESSED
      ].includes(res?.status)
    )?.length;

    if (!total) setShowPanel(false);

    return `Upload Resources (${completed}/${total})`;
  }, [uploadedResources]);

  return {
    userId: user.ID,
    title,
    offset,
    expanded,
    showPanel,
    headerElements,
    minmizedElements,
    uploadedResources,

    open,
    close,
    setOffset,
    onUploadCancel,
    onUploadFinished
  };
};
