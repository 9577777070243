import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useMutation, useQuery } from '@tanstack/react-query';

import { useSnackbar } from 'notistack';
import axios from 'axios';
import { Link, Resource } from '../../../../interfaces';
import {
  LinkObjectType,
  RESOURCE_CLASS,
  RESOURCE_STATUS,
  RESOURCE_UPLOAD_STATUS,
  ResourceApi,
  ResourceType
} from '../../../../enums';
import {
  createResourceMutation,
  getResourcesProcessStatusesQuery,
  resourceUploadFinishedMutation
} from '../../../../services';
import { GetResourceTypeName } from '../../../../utils';

interface UseContentProps {
  handleInsert?: (link: Link) => void;
}

const normalizeCreatedWeblink = (resource: Resource): Link => ({
  Name: resource.Name,
  Placeholder: uuidv4(),
  Item: {
    Items: [
      {
        ID: resource.ID,
        Name: resource.Name,
        LastChangedDate: new Date().toISOString(),
        ResourceTypeID: resource.ResourceTypeID,
        ThumbURL: resource.ThumbURLWithSas,
        ResourceTypeName: GetResourceTypeName(resource.ResourceTypeID),
        ResourceID: resource.ResourceID,
        ObjectType: LinkObjectType.Resources
      }
    ]
  }
});

const errorMessage = 'There was an error adding the resource';

export const useWebLink = ({ handleInsert }: UseContentProps) => {
  const [webLink, setWebLink] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const triggerAPI = useMemo(() => {
    if (!webLink) return false;

    const processed = [
      RESOURCE_UPLOAD_STATUS.ERROR,
      RESOURCE_UPLOAD_STATUS.PROCESSED,
      RESOURCE_UPLOAD_STATUS.BULK_PROCESSED
    ].includes(webLink?.status);

    if (processed) return false;

    return true;
  }, [webLink]);

  const { mutateAsync: addWebLink, isLoading } = useMutation(
    createResourceMutation()
  );
  const { mutateAsync: uploadFinish, isLoading: uploadFinishLoading } =
    useMutation(resourceUploadFinishedMutation());

  const { data } = useQuery({
    ...getResourcesProcessStatusesQuery([webLink?.Id]),
    enabled: triggerAPI,
    refetchInterval: 4000
  });

  const createResource = useCallback(
    async (webText: string, url: string) => {
      const body = {
        Resources: [
          {
            FileName: url,
            CategoryId: 0,
            ResourceStatus: RESOURCE_STATUS.ACTIVE,
            ResourceClass: RESOURCE_CLASS.USER,
            ResourceName: webText,
            ResourceType: ResourceType.WebPage,
            isShareable: true
          }
        ]
      };

      const uploadedResourceResponse = await addWebLink(body);

      const { Data: uploadedResponse } = await uploadFinish({
        resources: [
          {
            resourceId: uploadedResourceResponse?.Data?.[0]?.Id,
            filestackId: '',
            material: url || ''
          }
        ]
      });

      return uploadedResponse?.[0];
    },
    [addWebLink, uploadFinish]
  );

  const onInsertWebLink = useCallback(
    async (webText: string, url: string) => {
      const uploadResource = await createResource(webText, url);

      if (!uploadResource)
        return enqueueSnackbar(errorMessage, {
          variant: 'error'
        });

      return setWebLink(uploadResource);
    },
    [createResource, enqueueSnackbar]
  );

  const createLink = async () => {
    const Id = webLink?.Id?.toString();

    const uploadedResourceDetails = await axios
      .get(
        `${process.env.API_BASE_URL}${ResourceApi.GetResource.replace(
          ':resourceId',
          Id
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
      .then(response => response.data);
    if (!uploadedResourceDetails) {
      enqueueSnackbar(errorMessage, {
        variant: 'success'
      });
      return;
    }

    handleInsert(normalizeCreatedWeblink(uploadedResourceDetails));
    enqueueSnackbar('Resource uploaded successfully', {
      variant: 'success'
    });
  };

  useEffect(() => {
    if (!data || !webLink) return;
    if (data?.[0].Processing === RESOURCE_UPLOAD_STATUS.ERROR) {
      setWebLink(null);
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      });
    }
    if (data?.[0].Processing === RESOURCE_UPLOAD_STATUS.PROCESSED) {
      createLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, webLink]);

  return {
    onInsertWebLink,
    isLoading: isLoading || triggerAPI || uploadFinishLoading
  };
};
