import { Box, withStyles } from '@material-ui/core';
import { SCheckbox, SHighlightText } from '@setvi/shared/components';

export const ItemContainer = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gridGap: spacing(2),
    padding: spacing(2)
  }
}))(Box);

interface ContactProps {
  label: string;
  isSelected: boolean;
  searchTerm?: string;
  onSelect: (checked: boolean) => void;
}

export const ListItem = ({
  label = '-',
  isSelected,
  searchTerm,
  onSelect
}: ContactProps) => (
  <ItemContainer>
    <SCheckbox
      checked={isSelected}
      onChange={e => onSelect(e.target.checked)}
    />
    <SHighlightText value={label} searchQuery={searchTerm} />
  </ItemContainer>
);
