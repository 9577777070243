import { Box } from '@material-ui/core';
import { SCheckbox, SText } from '@setvi/shared/components';
import { Account as AccountType } from '../../../../../../../services/react-query/query/contacts/types';

interface AccountProps {
  account: AccountType;
  isSelected: boolean;
  onSelect: (checked: boolean, account: AccountType) => void;
}

export const Account = ({ account, isSelected, onSelect }: AccountProps) => (
  <Box key={account.Id} display="flex" alignItems="center" gridGap={10} p={2}>
    <SCheckbox
      checked={isSelected}
      onChange={e => onSelect(e.target.checked, account)}
    />
    <Box>
      <SText title={account.Name} />
    </Box>
  </Box>
);
