import { ReactNode } from 'react';
import { Box } from '@material-ui/core';

import { EmptyState } from './empty';
import { ColumnsType } from '../../../../../types';
import { TableLoader } from '../../../../sloaders';

interface TableStateProps {
  loading: boolean;
  isSearch: boolean;
  dataLength: number;
  customEmptyState?: ReactNode;
  columns?: ColumnsType;
}

export const TableState = ({
  loading,
  isSearch,
  dataLength,
  customEmptyState,
  columns
}: TableStateProps) => {
  if (loading) return <TableLoader columns={columns} />;

  if (customEmptyState && !isSearch && dataLength === 0)
    return <>{customEmptyState}</>;

  if (dataLength === 0)
    return (
      <Box width="100%" height="100%" display="flex">
        <EmptyState search={isSearch} />
      </Box>
    );

  return <></>;
};
