import { AxiosMethods, RFQApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

const body = {
  topK: 3,
  threshold: 0.5
};

const headers = {
  'Content-Type': 'application/json',
  Accept: 'text/plain'
};

export const getProductsByUrl = () => ({
  mutationFn: (url: string) =>
    axiosHelper({
      endpoint: RFQApi.GetProductsByUrl,
      method: AxiosMethods.POST,
      baseURL: process.env.INTELLIGENCE_API_BASE_URL,
      headers,
      body: {
        url,
        type: 'Standard',
        ...body
      }
    })
});

export const getProductsByText = () => ({
  mutationFn: (text: string) =>
    axiosHelper({
      endpoint: RFQApi.GetProductsByText,
      method: AxiosMethods.POST,
      baseURL: process.env.INTELLIGENCE_API_BASE_URL,
      headers,
      body: {
        text,
        ...body
      }
    })
});
