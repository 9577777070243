import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { getHubspotContacts } from '../../../../../services';
import {
  HubspotCompany,
  HubspotState
} from '../../../../../services/react-query/query/contacts/types';

const CONTACTS_LIMIT = 20;

export const useHubspotContacts = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStates, setSelectedStates] = useState<HubspotState[]>([]);
  const [selectedCities, setSelectedCities] = useState<HubspotCompany[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<HubspotCompany[]>(
    []
  );

  const queryVariables = useMemo(
    () => ({
      pageNumber: 1,
      pageSize: CONTACTS_LIMIT,
      searchString: searchTerm,
      companies: selectedCompanies.map(company => company.AssociatedCompanyId),
      states: selectedStates.map(state => state.State),
      cities: selectedCities.map(city => city.City)
    }),
    [searchTerm, selectedCities, selectedCompanies, selectedStates]
  );

  const {
    data: contacts,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(getHubspotContacts(queryVariables));

  const fetchMoreContacts = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return {
    searchTerm,
    setSearchTerm,
    isLoading: isLoading || isFetching,
    contacts: contacts?.pages.flatMap(page => page.Data) || [],
    fetchMoreContacts,
    selectedStates,
    setSelectedStates,
    selectedCities,
    setSelectedCities,
    setSelectedCompanies,
    selectedCompanies
  };
};
