import { useState } from 'react';
import { useCities } from './useCities';
import { FilterContainer, ListItem, Loader } from '../components';
import SInfiniteScroll from '../../../../../../sinfinite-scroll';
import { DrawerListState } from '../../../../../components/list-state';
import { SliderContent } from '../..';

interface CityFilterProps {
  selectedCities: any[];
  setSelectedCities: (cities: any[]) => void;
  handleClose: () => void;
}

export const CityFilter = ({
  selectedCities,
  setSelectedCities,
  handleClose
}: CityFilterProps) => {
  const [localSelectedCities, setLocalSelectedCities] =
    useState<any[]>(selectedCities);

  const { cities, searchTerm, isLoading, fetchMore, setSearchTerm } =
    useCities();

  const handleSelect = (checked: boolean, city: any) => {
    if (checked) {
      setLocalSelectedCities(prevAccounts => [...prevAccounts, city]);
    } else {
      setLocalSelectedCities(
        localSelectedCities.filter(i => i.City !== city.City)
      );
    }
  };

  return (
    <FilterContainer
      filterBy={SliderContent.City}
      selected={!!localSelectedCities.length}
      handleSearch={setSearchTerm}
      handleClear={() => {
        setSearchTerm('');
        setLocalSelectedCities([]);
      }}
      handleApply={() => {
        setSearchTerm('');
        setSelectedCities(localSelectedCities);
      }}
      handleClose={handleClose}>
      <SInfiniteScroll
        list={cities}
        keyGetter={city =>
          `${JSON.stringify(city.City)}-${JSON.stringify(city.State)}}`
        }
        fetchMore={fetchMore}
        render={city => (
          <ListItem
            label={city.City}
            searchTerm={searchTerm}
            isSelected={localSelectedCities.some(i => i.City === city.City)}
            onSelect={checked => handleSelect(checked, city)}
          />
        )}
      />
      {isLoading && <Loader />}
      {!isLoading && cities?.length === 0 && (
        <DrawerListState title="No Cities Found" />
      )}
    </FilterContainer>
  );
};
