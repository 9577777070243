import { useCallback, useMemo } from 'react';

import { Link, Recipient, Resource } from '@setvi/shared/interfaces';
import { DrawerContent } from '@setvi/shared/enums/common';

import { Favorites } from './pages/favorites';
import { CompanyResources } from './pages/company-resources';
import { RequiredReading } from './pages/required-reading';
import { MyResources } from './pages/my-resources';
import { Presentations } from './pages/presentations';
import { Templates } from './pages/templates';
import { Snippets } from './pages/snippets';
import { WebLink } from './pages/web-link';
import { Upload } from './pages/upload';
import {
  ContactsNavigation,
  SalesforceUser
} from './pages/contacts-navigation';
import { CrmLeads } from './pages/crm/leads';
import { Groups } from './pages/groups';
import { ReviewLinks } from './pages/review-links';
import { Base } from './components/base';
import { MainNavigation } from './pages/main-navigation';
import { TContact } from './components/contact';
import { Contacts } from './pages/contacts';
import { CrmAccounts } from './pages/crm/accounts';
import { CrmHubspot } from './pages/crm/hubspot';
import { RecentContacts } from './pages/contacts-recent';
import { CompanyModule } from '../../services/react-query/query/user/types';

interface SDrawerProps {
  link?: Link;
  sasToken?: string;
  links?: Array<Link>;
  userId?: number;
  algoliaKey?: string;
  hideEditLink?: boolean;
  showContent: DrawerContent | null;
  salesforceUser?: SalesforceUser;
  selectedContacts?: Recipient[];
  options?: DrawerContent[];
  staticResources?: Resource[];
  companyModules?: CompanyModule[];

  handleBack?: () => void;
  handleEdit?: (link: Link) => void;
  handleClose: () => void;
  handleOpen?: () => void;
  handleRemoveLink?: (link?: Link) => void;
  insertMessage?: (message: string) => void;
  modifyRecipientList?: (contact: TContact) => void;
  modifyRecipientListWithListOfContacts?: (
    contacts: TContact[],
    add: boolean
  ) => void;
  setShowContent?: (content: DrawerContent | null) => void;
  handleInsert?: (link: Link, currentLink?: Link) => void;
  useLinkForm?: boolean;
  hideBackButton?: boolean;
}

const SDrawerContent = ({
  link,
  links,
  userId,
  sasToken,
  algoliaKey,
  showContent,
  salesforceUser,
  selectedContacts,
  options,
  hideEditLink,
  staticResources,
  companyModules,
  handleBack,
  handleEdit,
  handleClose,
  handleOpen,
  handleInsert,
  insertMessage,
  modifyRecipientList,
  modifyRecipientListWithListOfContacts,
  setShowContent,
  handleRemoveLink,
  useLinkForm = true,
  hideBackButton = false
}: SDrawerProps) => {
  const goToMenu = useCallback(
    () => setShowContent?.(DrawerContent.resourcesNavigation),
    [setShowContent]
  );

  const onInsertLink = useCallback(
    (currLink: Link) => {
      handleInsert(currLink);
      if (!hideBackButton) goToMenu();
    },
    [handleInsert, goToMenu, hideBackButton]
  );

  const goToReviewLinks = useCallback(() => {
    setShowContent?.(DrawerContent.reviewLinks);
  }, [setShowContent]);

  const sections = useMemo(
    () => ({
      [DrawerContent.favorites]: (
        <Favorites
          handleBack={goToMenu}
          handleClose={handleClose}
          handleInsert={onInsertLink}
          useLinkForm={useLinkForm}
          hideBackButton={hideBackButton}
        />
      ),
      [DrawerContent.resources]: (
        <CompanyResources
          algoliaKey={algoliaKey}
          sasToken={sasToken}
          handleBack={goToMenu}
          handleClose={handleClose}
          handleInsert={onInsertLink}
          useLinkForm={useLinkForm}
          hideBackButton={hideBackButton}
        />
      ),
      [DrawerContent.myResources]: (
        <MyResources
          algoliaKey={algoliaKey}
          sasToken={sasToken}
          handleBack={goToMenu}
          handleClose={handleClose}
          handleInsert={onInsertLink}
          useLinkForm={useLinkForm}
          hideBackButton={hideBackButton}
        />
      ),
      [DrawerContent.requiredReading]: (
        <RequiredReading
          hideEditLink={hideEditLink}
          algoliaKey={algoliaKey}
          sasToken={sasToken}
          handleClose={handleClose}
          handleInsert={onInsertLink}
          useLinkForm={useLinkForm}
          preselectedResources={staticResources}
        />
      ),
      [DrawerContent.uploadFile]: (
        <Upload
          userId={userId}
          handleBack={goToMenu}
          handleComplete={hideEditLink ? goToMenu : links && goToReviewLinks}
          handleInsert={handleInsert}
          handleClose={handleClose}
          hideBackButton={hideBackButton}
        />
      ),
      [DrawerContent.presentations]: (
        <Presentations
          handleBack={goToMenu}
          handleInsert={onInsertLink}
          handleClose={handleClose}
          hideBackButton={hideBackButton}
        />
      ),
      [DrawerContent.templates]: (
        <Templates
          handleBack={goToMenu}
          handleInsert={onInsertLink}
          handleClose={handleClose}
          hideBackButton={hideBackButton}
        />
      ),
      [DrawerContent.webLink]: (
        <WebLink
          handleBack={goToMenu}
          handleInsert={onInsertLink}
          handleClose={handleClose}
          hideBackButton={hideBackButton}
        />
      ),
      [DrawerContent.snippet]: (
        <Snippets
          handleBack={goToMenu}
          handleInsertMessage={insertMessage}
          handleClose={handleClose}
          hideBackButton={hideBackButton}
        />
      ),
      [DrawerContent.contactsNavigation]: (
        <ContactsNavigation
          salesforceUser={salesforceUser}
          setShowContent={setShowContent}
          handleClose={handleClose}
          companyModules={companyModules}
        />
      ),
      [DrawerContent.resourcesNavigation]: (
        <MainNavigation
          setShowContent={setShowContent}
          handleClose={handleClose}
          options={options}
        />
      ),
      [DrawerContent.groups]: (
        <Groups
          modifyRecipientList={modifyRecipientList}
          setShowContent={setShowContent}
          handleClose={handleClose}
          selectedContacts={selectedContacts}
          modifyRecipientListWithListOfContacts={
            modifyRecipientListWithListOfContacts
          }
        />
      ),
      [DrawerContent.crmLeads]: (
        <CrmLeads
          salesforceUser={salesforceUser}
          selectedContacts={selectedContacts}
          modifyRecipientList={modifyRecipientList}
          setShowContent={setShowContent}
          handleClose={handleClose}
        />
      ),
      [DrawerContent.contacts]: (
        <Contacts
          selectedContacts={selectedContacts}
          modifyRecipientList={modifyRecipientList}
          setShowContent={setShowContent}
          handleClose={handleClose}
        />
      ),
      [DrawerContent.crmAccounts]: (
        <CrmAccounts
          selectedContacts={selectedContacts}
          modifyRecipientList={modifyRecipientList}
          setShowContent={setShowContent}
          handleClose={handleClose}
        />
      ),
      [DrawerContent.hubspotContacts]: (
        <CrmHubspot
          selectedContacts={selectedContacts}
          modifyRecipientList={modifyRecipientList}
          setShowContent={setShowContent}
          handleClose={handleClose}
        />
      ),
      [DrawerContent.recentContacts]: (
        <RecentContacts
          selectedContacts={selectedContacts}
          modifyRecipientList={modifyRecipientList}
          setShowContent={setShowContent}
          handleClose={handleClose}
        />
      ),
      [DrawerContent.reviewLinks]: (
        <ReviewLinks
          link={link}
          links={links}
          handleBack={handleBack}
          handleEdit={handleEdit}
          handleClose={handleClose}
          handleOpen={handleOpen}
          handleInsert={handleInsert}
          handleRemoveLink={handleRemoveLink}
        />
      )
    }),
    [
      goToMenu,
      handleClose,
      onInsertLink,
      useLinkForm,
      hideBackButton,
      algoliaKey,
      sasToken,
      hideEditLink,
      staticResources,
      userId,
      links,
      goToReviewLinks,
      handleInsert,
      insertMessage,
      salesforceUser,
      setShowContent,
      options,
      modifyRecipientList,
      selectedContacts,
      modifyRecipientListWithListOfContacts,
      link,
      handleBack,
      handleEdit,
      handleOpen,
      handleRemoveLink,
      companyModules
    ]
  );

  return (
    <Base>
      <>
        {!showContent ? (
          <MainNavigation
            setShowContent={setShowContent}
            handleClose={handleClose}
            options={options}
          />
        ) : (
          sections[showContent]
        )}
      </>
    </Base>
  );
};

export default SDrawerContent;
