import { useCallback, useMemo, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getSalesforceAccounts } from '../../../../../services';
import {
  City,
  State
} from '../../../../../services/react-query/query/contacts/types';

const ACCOUNTS_LIMIT = 20;
interface useAccountsProps {
  states?: State['StateCode'][];
  cities?: City[];
}

export const useAccounts = ({ states = [], cities = [] }: useAccountsProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const queryVariables = useMemo(
    () => ({
      pageNumber: 1,
      pageSize: ACCOUNTS_LIMIT,
      searchString: searchTerm,
      states,
      cities: cities.map(c => c.City)
    }),
    [searchTerm, states, cities]
  );

  const {
    data: accounts,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(getSalesforceAccounts(queryVariables));

  const fetchMoreAccounts = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return {
    accounts: accounts?.pages?.map(page => page?.Data).flat(1) || [],
    isLoading: isLoading || isFetching,
    setSearchTerm,
    searchTerm,
    fetchMoreAccounts
  };
};
