import { useCallback, useMemo } from 'react';
import { List } from '@material-ui/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { RolesEnumId } from '@setvi/shared/enums';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { CompanyModule } from '@setvi/shared/services/react-query/query/user/types';
import { Item } from './Item';
import { MenuItemList } from './MenutemList';
import { MenuItemDropdown } from './MenuItemDropdown';
import { AdminLabel, adminMenuItems, useUserMenuItems } from './items';

export enum CustomMenuVariant {
  Extended = 'EXTENDED',
  Collapsed = 'COLLAPSED'
}

interface IMenuItems {
  collapsedDrawer: boolean;
  expandedSidebarMenuItem: any;
  setExpandedSidebarMenuItem: (expandedSidebarMenuItems: any) => void;
}

export interface MenuItemProps {
  title: string;
  flag?: string;
  moduleId?: CompanyModule['ModuleID'];
  icon?: JSX.Element;
  route?: string;
  baseRoute?: string;
  list?: MenuItemProps[];
}

const showNavigationItem = (
  item: MenuItemProps,
  flags: any,
  companyModules: CompanyModule[]
): boolean => {
  if (item.moduleId && item.flag)
    return (
      companyModules?.some(module => module.ModuleID === item.moduleId) &&
      flags[item.flag]
    );

  if (item.moduleId)
    return companyModules?.some(module => module.ModuleID === item.moduleId);

  if (item.flag) return flags[item.flag];

  return true;
};

const filterNavigationList = (
  items: MenuItemProps[],
  flags: any,
  companyModules?: CompanyModule[]
): MenuItemProps[] =>
  items
    .filter(item => showNavigationItem(item, flags, companyModules))
    .map(item => {
      if (item.list?.length > 0)
        return {
          ...item,
          list: item.list.filter(listItem =>
            showNavigationItem(listItem, flags, companyModules)
          )
        };
      return item;
    });

export const MenuItems = ({
  collapsedDrawer,
  expandedSidebarMenuItem,
  setExpandedSidebarMenuItem
}: IMenuItems) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, companyModules } = useAppContext();
  const flags = useFlags();
  const { userMenuItems } = useUserMenuItems();

  const filteredAdminMenuItems = useMemo(
    () => filterNavigationList(adminMenuItems, flags),
    [flags]
  );

  const filteredUserMenuItems = useMemo(
    () => filterNavigationList(userMenuItems, flags, companyModules),
    [flags, userMenuItems, companyModules]
  );

  const renderMenuItem = useCallback(
    (item: MenuItemProps) => {
      if (item.list && item.list.length === 0) return null;
      // eslint-disable-next-line prefer-destructuring, no-param-reassign
      if (item.list?.length === 1) item = { ...item.list[0], icon: item.icon };

      if (!collapsedDrawer && item.list?.length > 0)
        return (
          <MenuItemList
            key={item.title}
            title={item.title}
            icon={item.icon}
            menuItems={item.list}
            collapsedDrawer={collapsedDrawer}
            isExpanded={expandedSidebarMenuItem[item.title]}
            onClick={() => {
              setExpandedSidebarMenuItem({
                ...expandedSidebarMenuItem,
                [item.title]: !expandedSidebarMenuItem[item.title]
              });
            }}
          />
        );

      if (collapsedDrawer && item.list?.length > 0)
        return (
          <MenuItemDropdown
            key={item.title}
            title={item.title}
            icon={item.icon}
            collapsedDrawer={collapsedDrawer}
            menuItems={item.list}
            baseRoute={item.baseRoute}
            route={item.route}
          />
        );

      return (
        <Item
          key={item.title}
          title={item.title}
          icon={item.icon}
          route={item.route}
          list={item.list}
          onClick={() => navigate(item.route)}
          collapsedDrawer={collapsedDrawer}
          selected={
            location.pathname === item.route ||
            location.pathname.startsWith(item.baseRoute)
          }
        />
      );
    },
    [
      collapsedDrawer,
      expandedSidebarMenuItem,
      location.pathname,
      setExpandedSidebarMenuItem,
      navigate
    ]
  );

  return (
    <List component="nav">
      {filteredUserMenuItems.map(renderMenuItem)}
      {[RolesEnumId.ADMIN, RolesEnumId.STANDARD_ADMIN].includes(user.Role) &&
        filteredAdminMenuItems?.[0]?.list?.length > 0 && (
          <>
            <AdminLabel collapsedDrawer={collapsedDrawer} />
            {filteredAdminMenuItems.map(renderMenuItem)}
          </>
        )}
    </List>
  );
};
