import { Theme, makeStyles } from '@material-ui/core';

export const useVirtualTableStyles = makeStyles<
  Theme,
  { wrapperClick: boolean; height: number; isSafari: boolean }
>({
  wrapper: {
    cursor: ({ wrapperClick }) => wrapperClick && 'pointer',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #E6E7E9'
  },
  tbody: {
    display: 'table-row',
    height: ({ height }) => (height ? `${height}px` : '100%'),
    width: '100%',
    position: 'relative'
  },
  table: {
    borderCollapse: 'collapse',
    position: ({ isSafari }) => (isSafari ? 'relative' : 'inherit'),

    '& tbody > tr': {
      marginTop: ({ isSafari }) => (isSafari ? 35 : 0)
    }
  }
});
