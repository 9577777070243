import { useCallback } from 'react';
import platform from 'platform';
import CryptoJS from 'crypto-js';
import { useSnackbar } from 'notistack';
import { client } from 'filestack-react';
import { EditorEvent, Editor as TinyMCEEditor } from 'tinymce';

import { PastePostProcessEvent, RangeObject } from '../../interfaces';
import { Browsers, EditorImagesIds } from '../../../../enums';

export const useImages = () => {
  const { enqueueSnackbar } = useSnackbar();

  const fileStackUpload = useCallback(
    (file: File) =>
      client
        .init(process.env.FILESTACK_KEY)
        .upload(file, {
          onProgress: async ({ totalPercent }: { totalPercent: number }) =>
            totalPercent
        })
        .then(({ url }) => url)
        .catch(() => {
          enqueueSnackbar(
            'There was an error uploading your file, please try again later',
            {
              variant: 'error'
            }
          );
        }),
    [enqueueSnackbar]
  );

  const addImage = async (file: File, editor: TinyMCEEditor, id: string) => {
    if (platform.name === Browsers.Safari) {
      const range = editor.selection.getRng(); // get range

      const placholderHtml = editor.getDoc().createElement('span');
      placholderHtml.setAttribute('id', id);
      placholderHtml.setAttribute('class', 'image-placeholder');
      placholderHtml.setAttribute('contentEditable', 'false');
      placholderHtml.innerHTML = 'Loading...';
      range.insertNode(placholderHtml);

      const url = await fileStackUpload(file);
      await editor?.dom?.getRoot()?.querySelector(`[id="${id}"]`)?.remove();

      if (!url) return;

      const imageHtml = editor.getDoc().createElement('img');
      imageHtml.setAttribute('src', url);
      imageHtml.setAttribute('alt', 'Editor image');
      imageHtml.setAttribute('width', '300px');
      imageHtml.setAttribute('height', 'auto');
      range.insertNode(imageHtml);

      await editor.execCommand('mceInsertNewLine');
      return;
    }

    const placholderHtml = `<span id=${id} class='image-placeholder' contentEditable="false">Loading...</span>`;
    await editor.execCommand('mceInsertContent', false, placholderHtml);

    const url = await fileStackUpload(file);
    await editor?.dom?.getRoot()?.querySelector(`[id="${id}"]`)?.remove();

    if (!url) return;

    const imageHtml2 = `<img id="${EditorImagesIds.FILESTACK}" src=${url} alt="Editor image" width='300px' height='auto'  />`;
    await editor.execCommand('mceInsertContent', false, imageHtml2);
  };

  const uploadLocalImage = (editor: TinyMCEEditor) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!file) return;
      // Check if uploaded file is image
      if (!file.type.includes('image/')) {
        enqueueSnackbar('Unsupported file', {
          variant: 'error'
        });
        return;
      }

      await addImage(input.files[0], editor, 'local-image-placeholder');
    };
  };

  const isCursorNextToImg = (range: RangeObject) => {
    const container = range?.startContainer;
    const offset = range?.startOffset;

    // Check the node before the cursor
    const prevNode = container?.childNodes[offset - 1] as Element;

    if (
      prevNode &&
      prevNode.nodeType === Node.ELEMENT_NODE &&
      prevNode.tagName === 'IMG' &&
      prevNode?.getAttribute('id') === EditorImagesIds.FILESTACK
    )
      return prevNode;

    return false;
  };

  const deleteImage = async (
    e: {
      keyCode: number;
      preventDefault: () => void;
    },
    editor: TinyMCEEditor
  ) => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      // Check if the backspace (8) or delete (46) key is pressed

      const selection = editor?.selection;
      const selectedNode = isCursorNextToImg(selection.getRng());
      if (!selectedNode) return;

      if (selectedNode?.hasAttribute('src')) {
        // If the selected node is an <a> tag, delete only the selected node
        const currentImage = selectedNode.getAttribute('src').split('/').pop();
        if (!currentImage) return;

        const newPolicy = {
          expiry: Math.floor(Date.now() / 1000) + 10 // Set expiration time (in this example, 1 hour from now)
          // Add other policy details as needed
        };

        // Convert the security policy to base64-encoded JSON
        const policyBase64 = btoa(JSON.stringify(newPolicy));

        // Generate the signature using your Filestack API secret
        const newSignature = CryptoJS.HmacSHA256(
          policyBase64,
          process.env.FILESTACK_HASH_KEY
        ).toString();

        await client
          .init(process.env.FILESTACK_KEY, {
            security: {
              policy: policyBase64,
              signature: newSignature
            }
          })
          .remove(currentImage)
          .then(() => {
            const range = selection.getRng();

            if (!selectedNode?.parentNode) return;
            range.setStartBefore(selectedNode);
            range.setEndAfter(selectedNode);
            selection.setRng(range);

            e.preventDefault();
          })
          .catch(() => {
            enqueueSnackbar(
              'There was an error removing your file, please try again later',
              {
                variant: 'error'
              }
            );
          });
      }
    }
  };

  const handlePastedImages = async (
    e: EditorEvent<PastePostProcessEvent>,
    editor: TinyMCEEditor
  ) => {
    const node = e?.node?.getElementsByTagName('IMG');

    if (node?.length) {
      for (let i = 0; i < node.length; i += 1) {
        const src = node[i]?.getAttribute('src');

        fetch(src, {
          method: 'GET',
          headers: {}
        })
          .then(async response => {
            response.arrayBuffer().then(async buffer => {
              const file = new File([buffer], 'name');
              const id = `pasted-setvi-image-${i + 1}`;
              await addImage(file, editor, id);
            });
          })
          .catch(() => {
            enqueueSnackbar(
              'This image cannot be uploaded, please try other image.',
              {
                variant: 'error'
              }
            );
          });
      }

      e.preventDefault();
    }
  };

  return {
    handlePastedImages,
    uploadLocalImage,
    deleteImage
  };
};
