import { useMemo } from 'react';
import {
  Box,
  Divider,
  makeStyles,
  IconButton,
  createStyles
} from '@material-ui/core';

import CancelIcon from '@material-ui/icons/Cancel';
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';

import SText from '../../../../../sui/stext';
import { CheckCircleIconGreen } from '../../../../../sicons';
import { RESOURCE_UPLOAD_STATUS } from '../../../../../../enums';

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: '$spin 2s linear infinite'
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(360deg)'
      },
      '100%': {
        transform: 'rotate(0deg)'
      }
    }
  })
);
interface ResourceUploadStatusProps {
  error?: string;
  status: RESOURCE_UPLOAD_STATUS;
  progress?: number;
  unzipedTotal?: number;
  unzipedProcesed?: number;
  onUploadRetry?: () => void;
  onUploadCancel?: () => void;
}

const iconStyle = {
  padding: 2,
  margin: 0
};

const ResourceUploadStatus = ({
  error,
  status,
  progress,
  unzipedTotal,
  unzipedProcesed,

  onUploadRetry,
  onUploadCancel
}: ResourceUploadStatusProps) => {
  const classes = useStyles();

  const renderStatus = useMemo(
    () => [
      {
        title: `${progress}%`,
        fontColor: '#2196F3',
        icon: (
          <IconButton onClick={onUploadCancel} style={iconStyle}>
            <CancelIcon />
          </IconButton>
        ),
        status: [RESOURCE_UPLOAD_STATUS.FILESTACK_UPLOADING],

        error: ''
      },
      {
        title: `Processing  ${
          unzipedTotal ? `${unzipedProcesed}/${unzipedTotal}` : ''
        } `,
        fontColor: '#FF974A',
        icon: (
          <IconButton disabled style={iconStyle}>
            <ReplayRoundedIcon
              htmlColor="#FF974A"
              className={classes.rotateIcon}
            />
          </IconButton>
        ),
        status: [
          RESOURCE_UPLOAD_STATUS.UPLOADING,
          RESOURCE_UPLOAD_STATUS.PROCESSING,
          RESOURCE_UPLOAD_STATUS.UNPROCESSED,
          RESOURCE_UPLOAD_STATUS.BULK_UNPACKED,
          RESOURCE_UPLOAD_STATUS.REPLACE_UNPROCESSED,
          RESOURCE_UPLOAD_STATUS.REPLACING,
          RESOURCE_UPLOAD_STATUS.REPLACE_UPLOADING
        ],
        error: ''
      },
      {
        title: 'Completed',
        fontColor: '#171725',
        icon: (
          <IconButton disabled style={iconStyle}>
            <CheckCircleIconGreen />
          </IconButton>
        ),
        status: [
          RESOURCE_UPLOAD_STATUS.PROCESSED,
          RESOURCE_UPLOAD_STATUS.BULK_PROCESSED
        ],
        error: ''
      },
      {
        title: 'Error details',
        fontColor: '#FF0000',
        icon:
          status === RESOURCE_UPLOAD_STATUS.FILESTACK_ERROR ? (
            <Box display="flex" alignItems="center" gridGap={4}>
              <Divider orientation="vertical" flexItem />
              <SText size="sm" noWrap fontColor="#92929D" weight="medium">
                Retry
              </SText>
              <IconButton style={iconStyle} onClick={onUploadRetry}>
                <RefreshRoundedIcon htmlColor="#92929D" />
              </IconButton>
            </Box>
          ) : null,
        status: [
          RESOURCE_UPLOAD_STATUS.FILESTACK_ERROR,
          RESOURCE_UPLOAD_STATUS.ERROR
        ],
        error,
        disabed: true
      }
    ],
    [
      error,
      status,
      progress,
      unzipedTotal,
      unzipedProcesed,
      classes.rotateIcon,
      onUploadRetry,
      onUploadCancel
    ]
  );

  const currentStatus = useMemo(
    () => renderStatus.find(s => s.status.includes(status)),
    [renderStatus, status]
  );

  return (
    <Box display="flex" alignItems="center" gridGap={4}>
      <SText
        noWrap
        size="sm"
        showTooltip
        weight="semibold"
        title={currentStatus?.error}
        fontColor={currentStatus?.fontColor}
        style={{
          color: currentStatus?.fontColor
        }}>
        {currentStatus?.title}
      </SText>
      {currentStatus?.icon}
    </Box>
  );
};

export default ResourceUploadStatus;
