import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  Box,
  useTheme,
  IconButton,
  Typography,
  ClickAwayListener
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import ChipInput, { ChipRendererArgs, Props } from 'material-ui-chip-input';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';

import { Recipient } from '@setvi/shared/interfaces';
import { DrawerContent } from '@setvi/shared/enums';

import { debounce } from 'lodash';
import { ErrorContainer, useStyles as useInputStyles } from '../subject/style';
import { ChipInputContainer, InputContainer, useStyles } from './style';
import RecipientChip, { Chip } from '../../chip';

interface RecipientFieldProps {
  label: string;
  name: string;
  chips?: Chip[];
  placeholder?: string;
  fieldValue: Recipient | Recipient[] | string;
  numberOfPreviewChips?: number;
  blurBehavior?: Props['blurBehavior'];
  onChipAdd: (chip: string) => void;
  onChipDelete: (chip: Chip) => void;
  onClickAway?: () => void;
  handleDrawer: (type: DrawerContent) => void;
  onInputChange?: (value: string) => void;
}

const RecipientField = ({
  label,
  name,
  chips = [],
  placeholder,
  fieldValue,
  numberOfPreviewChips = 2,
  blurBehavior = 'clear',
  onChipAdd,
  onChipDelete,
  onClickAway,
  handleDrawer,
  onInputChange
}: RecipientFieldProps) => {
  const flags = useFlags();
  const classes = useStyles();
  const { palette } = useTheme();
  const inputClasses = useInputStyles();
  const chipInputRef = useRef<HTMLInputElement | null>(null);
  const { setFieldValue, getFieldMeta } = useFormikContext();
  const { touched, error } = getFieldMeta(name);
  const [showPreview, setShowPreview] = useState(false);

  const previewChips: Chip[] = useMemo(
    () => [
      ...chips.slice(0, numberOfPreviewChips),
      {
        label: `+${chips.length - numberOfPreviewChips} more`,
        disableChipTooltip: true,
        errors: chips.some((chip: Chip) => chip.errors.length)
          ? ['Some emails are invalid']
          : []
      }
    ],
    [chips, numberOfPreviewChips]
  );

  const chipRenderer = (
    { chip, handleClick, handleDelete }: ChipRendererArgs,
    key: any
  ) => (
    <RecipientChip
      key={key}
      chip={chip}
      handleClick={handleClick}
      handleDelete={!showPreview ? handleDelete : null}
    />
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce((val: string) => onInputChange?.(val), 300),
    []
  );

  useEffect(() => {
    chipInputRef?.current.focus();
  }, []);

  useEffect(() => {
    setFieldValue(name, fieldValue);
  }, [fieldValue, setFieldValue, name]);

  return (
    <>
      <InputContainer>
        <Box className={inputClasses.textLabelContainer}>
          <Typography
            variant="body2"
            color="textSecondary"
            className={inputClasses.textLabel}>
            {label}
          </Typography>
        </Box>

        <ClickAwayListener
          onClickAway={() => {
            onClickAway?.();
            setShowPreview(true);
          }}>
          <ChipInputContainer onClick={() => setShowPreview(false)}>
            <ChipInput
              disableUnderline
              ref={chipInputRef}
              blurBehavior={blurBehavior}
              placeholder={placeholder}
              chipRenderer={chipRenderer}
              className={classes.chipInput}
              alwaysShowPlaceholder={false}
              newChipKeyCodes={[13, 188, 9]}
              onAdd={(chip: string) => {
                onChipAdd(chip.trim());
                handleChange.cancel();
              }}
              onDelete={(chip: Chip) => onChipDelete(chip)}
              value={
                showPreview && chips.length > numberOfPreviewChips
                  ? previewChips
                  : chips
              }
              onUpdateInput={e => handleChange?.(e?.target?.value)}
            />
          </ChipInputContainer>
        </ClickAwayListener>

        {!!flags?.contactsGroups && (
          <IconButton
            onClick={() => handleDrawer(DrawerContent.contactsNavigation)}
            style={{
              padding: 4
            }}>
            <AddCircleOutlineRoundedIcon htmlColor={palette.primary.main} />
          </IconButton>
        )}
      </InputContainer>
      {touched && error && (
        <ErrorContainer>
          <Typography className={inputClasses.error}>
            {Array.isArray(error) ? error.find(err => err)?.Email : error}
          </Typography>
        </ErrorContainer>
      )}
    </>
  );
};

export default RecipientField;
