export * from './notifications';
export * from './categories';
export * from './campaigns';
export * from './groups';
export * from './resources';
export * from './company';
export * from './presentations';
export * from './templates';
export * from './contacts';
export * from './snippets';
export * from './banners';
export * from './outbox';
export * from './groups';
export * from './user';
export * from './common';
export * from './email-queue';
export * from './qrvey';
export * from './vetsuite';
export * from './digital-showroom';
export * from './orders';
export * from './accounts';
export * from './invoices';
export * from './request-for-quotes';

export enum AxiosMethods {
  DELETE = 'DELETE',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET'
}
