import { ReactNode, forwardRef, memo } from 'react';
import {
  Box,
  makeStyles,
  MenuItem,
  PopoverOrigin,
  Theme,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import clsx from 'clsx';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { useColors, EColors } from '../../hooks';
import { SDropdownMenu } from '../index';
import { TextColor } from '../../enums';

const DropdownText = withStyles({
  root: {
    fontSize: '0.9rem',
    color: 'inherit'
  }
})(Typography);

const DropdownSubtext = withStyles({
  root: {
    fontSize: '0.75rem',
    color: '#92929D',
    whiteSpace: 'normal'
  }
})(Typography);

const useMenuItemStyles = makeStyles<Theme, { color: string }>(
  ({ spacing }) => ({
    itemContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gridGap: '4px',
      padding: spacing(1),
      color: ({ color }) => color || '#212121'
    },
    labelWrapper: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    borderedBox: {
      border: '1px solid #F1F1F5',
      borderRadius: 6,
      minWidth: 135,
      height: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    innerBox: {
      padding: spacing(0, 2)
    },
    icon: {
      fill: ({ color }: { color: string }) => color || TextColor.Grey,
      color: ({ color }) => color || TextColor.Grey,
      margin: 0
    },
    disabled: {
      opacity: 0.5,
      cursor: 'not-allowed'
    },
    bold: {
      fontWeight: 600
    }
  })
);

export interface ISMenuItem {
  icon?: ReactNode;
  text: string;
  subText?: string;
  color?: string;
  disabled?: boolean;
  tooltipText?: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  boldText?: boolean;
  borderedBox?: boolean;
  closeOnClick?: boolean;
  onClick?: () => void;
  handleClose?: () => void;
  submenu?: ISMenuItem[];
}

interface SMenuItemProps extends ISMenuItem {
  submenuAnchorOrigin?: PopoverOrigin;
  submenuTransformOrigin?: PopoverOrigin;
}

const SMenuItem = forwardRef(
  (
    {
      icon,
      text,
      subText,
      color,
      disabled,
      tooltipText,
      tooltipPlacement = 'top',
      boldText,
      borderedBox,
      closeOnClick = true,
      onClick,
      handleClose,
      submenu,
      submenuAnchorOrigin,
      submenuTransformOrigin
    }: SMenuItemProps,
    ref
  ) => {
    const { newColor } = useColors({ color: color as EColors });

    const classes = useMenuItemStyles({ color: newColor });
    const hasSubmenu = submenu?.length > 0;

    const handleClick = () => {
      if (disabled) return;

      onClick?.();
      closeOnClick && handleClose();
    };

    const menuItem = (
      <MenuItem
        component="div"
        disabled={disabled}
        onClick={() => handleClick()}>
        <Box className={classes.itemContainer}>
          <>
            {icon && <Box className={classes.icon}>{icon}</Box>}
            <Box
              className={clsx(classes.labelWrapper, {
                [classes.borderedBox]: borderedBox
              })}>
              <Box className={classes.innerBox}>
                <DropdownText
                  className={clsx({
                    [classes.bold]: boldText
                  })}>
                  {text}
                </DropdownText>
                {subText && <DropdownSubtext>{subText}</DropdownSubtext>}
              </Box>
              {submenu?.length > 0 && <ChevronRightIcon />}
            </Box>
          </>
        </Box>
      </MenuItem>
    );

    return (
      <Tooltip
        key={text}
        ref={ref}
        title={tooltipText || ''}
        arrow
        disableHoverListener={Boolean(!tooltipText)}
        placement={tooltipPlacement}>
        <li>
          {hasSubmenu && !disabled ? (
            <SDropdownMenu
              hasSubmenu={hasSubmenu}
              anchorOrigin={
                submenuAnchorOrigin || {
                  vertical: 'bottom',
                  horizontal: 'right'
                }
              }
              transformOrigin={
                submenuTransformOrigin || {
                  vertical: 'top',
                  horizontal: 'right'
                }
              }
              onClose={handleClose}
              menuItems={submenu}>
              {menuItem}
            </SDropdownMenu>
          ) : (
            menuItem
          )}
        </li>
      </Tooltip>
    );
  }
);

export default memo(SMenuItem);
