import { useState } from 'react';
import { Header } from '@setvi/shared/components/sdrawer-content/components/header';
import { DrawerContent } from '@setvi/shared/enums/common';
import { SSearchInput } from '@setvi/shared/components/sforms/ssearch';
import { TContact } from '@setvi/shared/components/sdrawer-content/components/contact';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';
import { Box } from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { SEmptyState } from '@setvi/shared/components';
import { ContactsLoader } from '@setvi/shared/components/sdrawer-content/components/contact/loader';
import { Subheader } from '../../../components/subheader';
import { Recipient } from '../../../../../interfaces';
import { AddressBook } from '../../../components/contact/address-book';
import { useHubspotContacts } from './useHubspotContacts';
import { FloatingNavigation } from '../../../components/floating-navigation';
import {
  DrawerDialog,
  DrawerDialogContainer
} from '../../../components/dialog';
import { CityFilter, CompanyFilter, StateFilter } from './filters';

interface CrmHubspotProps {
  selectedContacts?: Recipient[];
  modifyRecipientList: (contact: TContact) => void;
  setShowContent: (content: DrawerContent | null) => void;
  handleClose: () => void;
}

export enum SliderContent {
  State = 'State',
  City = 'City',
  Company = 'Company'
}

export const CrmHubspot = ({
  selectedContacts,
  modifyRecipientList,
  setShowContent,
  handleClose
}: CrmHubspotProps) => {
  const [openedSlider, setOpenedSlider] =
    useState<keyof typeof SliderContent>(undefined);
  const {
    searchTerm,
    setSearchTerm,
    contacts,
    fetchMoreContacts,
    isLoading,
    selectedStates,
    setSelectedStates,
    selectedCities,
    setSelectedCities,
    selectedCompanies,
    setSelectedCompanies
  } = useHubspotContacts();

  const handleCloseSlider = () => setOpenedSlider(undefined);

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      position="relative"
      overflow="hidden">
      <Header title="Recipients" handleClose={handleClose} />
      <Subheader
        handleBack={() => setShowContent(DrawerContent.contactsNavigation)}
        text="CRM Contacts"
      />
      <Box p={2} mb={1}>
        <SSearchInput defaultValue={searchTerm} onChange={setSearchTerm} />
      </Box>
      <Box overflow="auto" style={{ paddingBottom: '60px' }}>
        <AddressBook
          contacts={contacts}
          selectedContacts={selectedContacts}
          fetchMore={fetchMoreContacts}
          onContactClick={modifyRecipientList}
          sortingParameter="Email">
          <>
            {isLoading && <ContactsLoader />}
            {!isLoading && contacts?.length === 0 && (
              <SEmptyState
                imageSrc="/images/no-contacts-2.png"
                subTitle="No contacts available"
              />
            )}
          </>
        </AddressBook>
      </Box>
      <FloatingNavigation
        items={[
          {
            label: SliderContent.State,
            startIcon: <MapOutlinedIcon />,
            active: !!selectedStates.length,
            onClick: () => setOpenedSlider(SliderContent.State)
          },
          {
            label: SliderContent.City,
            startIcon: <PinDropOutlinedIcon />,
            active: !!selectedCities.length,
            onClick: () => setOpenedSlider(SliderContent.City)
          },
          {
            label: SliderContent.Company,
            startIcon: <PermIdentityIcon />,
            active: !!selectedCompanies.length,
            onClick: () => setOpenedSlider(SliderContent.Company)
          }
        ]}
      />
      {Boolean(openedSlider) && (
        <DrawerDialogContainer onClick={handleCloseSlider}>
          <DrawerDialog onClick={e => e.stopPropagation()}>
            {openedSlider === SliderContent.State && (
              <StateFilter
                selectedStates={selectedStates}
                setSelectedStates={states => {
                  setSelectedStates(states);
                  handleCloseSlider();
                }}
                handleClose={handleCloseSlider}
              />
            )}
            {openedSlider === SliderContent.City && (
              <CityFilter
                selectedCities={selectedCities}
                setSelectedCities={cities => {
                  setSelectedCities(cities);
                  handleCloseSlider();
                }}
                handleClose={handleCloseSlider}
              />
            )}
            {openedSlider === SliderContent.Company && (
              <CompanyFilter
                selectedCompanies={selectedCompanies}
                setSelectedCompanies={companies => {
                  setSelectedCompanies(companies);
                  handleCloseSlider();
                }}
                handleClose={handleCloseSlider}
              />
            )}
          </DrawerDialog>
        </DrawerDialogContainer>
      )}
    </Box>
  );
};
