import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Realm from 'realm-web';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { useSnackbar } from 'notistack';
import { Navigate } from 'react-router-dom';
import { ROUTES } from 'enumsV2';
import { SText } from '@setvi/shared/components';
import { CompanySettingsTypeID } from '@setvi/shared/services/react-query/query/user/types';
import { Box, CircularProgress } from '@material-ui/core';
import { RealmContext } from './context';

interface RealmProviderProps {
  children: React.ReactNode;
}

export const RealmProvider = ({ children }: RealmProviderProps) => {
  const { token, companyData } = useAppContext();

  const app = useMemo(
    () =>
      new Realm.App({
        id:
          companyData.Settings[CompanySettingsTypeID.AtlasMongoProductAppId]
            ?.Value || ''
      }),
    [companyData]
  );
  const [client, setClient] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const initClient = useCallback(
    async (jwt: string) => {
      setIsLoading(true);
      const credentials = Realm.Credentials.jwt(jwt);

      try {
        const user = await app.logIn(credentials);
        setCurrentUser(user);
      } catch (e) {
        setError(e);
        return enqueueSnackbar(
          'Issue occured while getting module data, try again later or contact Admin if issue persists.',
          {
            variant: 'error'
          }
        );
      }

      return app.currentUser.mongoClient(process.env.MONGO_CLIENT);
    },
    [enqueueSnackbar, app]
  );

  useEffect(() => {
    if (token)
      initClient(token)
        .then(c => {
          setClient(c);
        })
        .finally(() => {
          setIsLoading(false);
        });
  }, [token, initClient]);

  if (isLoading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
        <Box display="flex">
          <Box marginRight={2}>
            <CircularProgress size={20} />
          </Box>
          <SText variant="subtitle2">Loading...</SText>
        </Box>
      </Box>
    );

  if (!isLoading && error) return <Navigate to={ROUTES.HOME} />;

  return (
    <RealmContext.Provider
      value={{
        client,
        user: currentUser
      }}>
      {children}
    </RealmContext.Provider>
  );
};
