import { CSSProperties } from 'react';
import { Typography, withStyles, TableCell } from '@material-ui/core';

import { useStyles } from '../../../styles';
import { ColumnsType } from '../../../../../types';
import SHighlightText from '../../../../shighlight-text';
import { TextColor } from '../../../../../enums';
import { distances } from '../../../../../styles';

export const STableCell = withStyles({
  root: {
    padding: distances.px.small,
    overflow: 'hidden',
    maxHeight: '55px',
    cursor: 'inherit',
    border: 'none'
  }
})(TableCell);

export interface TableCellsProps {
  columns: ColumnsType;
  rowData: any;
  loading?: boolean;
  searchQuery?: string;
  style?: CSSProperties;
}

const TableBodyCells = ({
  columns,
  rowData,
  searchQuery,
  loading = false,
  style = {}
}: TableCellsProps) => {
  const classes = useStyles();

  return (
    <>
      {columns.map(column => (
        <STableCell
          key={column.id}
          align={column?.align || 'left'}
          style={{
            width: column?.width,
            minWidth: column?.minWidth || 'min-content',
            maxWidth: column?.maxWidth || 'none',
            ...style
          }}>
          {column?.cellContent ? (
            column.cellContent(rowData, searchQuery, loading)
          ) : (
            <Typography className={classes.defaultText}>
              {rowData[column.dataKey] ? (
                <SHighlightText
                  size="sm"
                  fontColor={TextColor.DarkGrey}
                  searchQuery={searchQuery}
                  value={rowData[column.dataKey]}
                />
              ) : (
                column.placeholder || '-'
              )}
            </Typography>
          )}
        </STableCell>
      ))}
    </>
  );
};

export default TableBodyCells;
