import { CSSProperties, ReactElement } from 'react';
import { Tooltip, TooltipProps } from '@material-ui/core';

interface STooltipProps extends Omit<TooltipProps, 'children'> {
  style?: CSSProperties;
  children: ReactElement;
}

const STooltip = ({ style, children, ...tooltipProps }: STooltipProps) => (
  <Tooltip style={style} {...tooltipProps}>
    {children}
  </Tooltip>
);

export default STooltip;
