import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { getHubspotCities } from '../../../../../../../services';
import { CONTACT_FILTER_LIMIT } from '../company/useCompanies';

export const useCities = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const queryVariables = useMemo(
    () => ({
      pageNumber: 1,
      pageSize: CONTACT_FILTER_LIMIT,
      searchString: searchTerm
    }),
    [searchTerm]
  );

  const {
    data: cities,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(getHubspotCities(queryVariables));

  const fetchMore = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return {
    cities: cities?.pages?.map(page => page?.Data).flat(1) || [],
    isLoading: isLoading || isFetching,
    searchTerm,
    setSearchTerm,
    fetchMore
  };
};
