import { AxiosMethods } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

export const getOrderStatuses = () => ({
  queryKey: ['order_statuses'],
  queryFn: () =>
    axiosHelper({
      endpoint: '/order/statuses',
      method: AxiosMethods.GET,
      baseURL: process.env.REVOPS_API_BASE_URL
    }),
  refetchOnWindowFocus: false
});
