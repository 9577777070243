import { AxiosMethods, DigitalShowroomApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

import { CovetrusRFQInput } from './type';

export const createRFQMutation = () => ({
  mutationFn: (body: CovetrusRFQInput) =>
    axiosHelper({
      endpoint: DigitalShowroomApi.CovetrusRequestForQuote,
      method: AxiosMethods.POST,
      baseURL: process.env.REVOPS_API,
      body
    })
});
