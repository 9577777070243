import * as yup from 'yup';

import { CampaignFormLimit } from 'Enums/CampaignFormLimit';
import { emailRegex } from 'Utils/regex';
import { Recipient } from '@setvi/shared/interfaces';

const MAX_EMAIL_LENGTH = 100;

export const validationSchema = yup.object({
  sendTo: yup
    .array()
    .of(
      yup.object({
        Email: yup
          .string()
          .email('Invalid email')
          .max(MAX_EMAIL_LENGTH, 'Exceeded character limit for Email')
      })
    )
    .min(1, 'Email is required'),
  subject: yup
    .string()
    .required('Subject is required')
    .max(CampaignFormLimit.Subject, 'Exceeded character limit for Subject')
});

export const composeBodyValidation = yup.object({
  body: yup
    .string()
    .trim()
    .max(CampaignFormLimit.Body, 'Exceeded character limit for Body')
});

export const emailChipErrorHandler = (email: string): string[] => {
  const errors: string[] = [];

  if (email.length > MAX_EMAIL_LENGTH)
    errors.push('Exceeded character limit for Email');
  if (!emailRegex.test(email)) errors.push('Invalid email');

  return errors;
};

export const isValidEmail = (email: string): boolean => emailRegex.test(email);

export const getRecipientListFromExcelList = (input: string): Recipient[] => {
  const emailList = input.split(' ');
  const recipientList: Recipient[] = emailList.map((email: string) => ({
    Email: email
  }));

  return recipientList;
};

export const removeDuplicateRecipients = (
  recipients: Recipient[]
): Recipient[] => {
  const filteredRecipients = recipients.filter(
    (value, index, self) =>
      index ===
      self.findIndex(t => t.Email.toLowerCase() === value.Email.toLowerCase())
  );

  return filteredRecipients;
};
