import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { getHubspotCompanies } from '../../../../../../../services';

export const CONTACT_FILTER_LIMIT = 20;

export const useCompanies = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const queryVariables = useMemo(
    () => ({
      pageNumber: 1,
      pageSize: CONTACT_FILTER_LIMIT,
      searchString: searchTerm
    }),
    [searchTerm]
  );

  const {
    data: companies,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(getHubspotCompanies(queryVariables));

  const fetchMoreCompanies = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return {
    companies: companies?.pages?.map(page => page?.Data).flat(1) || [],
    isLoading: isLoading || isFetching,
    searchTerm,
    setSearchTerm,
    fetchMoreCompanies
  };
};
