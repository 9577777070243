import { makeStyles, Theme } from '@material-ui/core';
import { TextColor } from '../../../../../../enums';

interface StyleProps {
  open?: boolean;
  width?: number;
  noBorder?: boolean;
}

export const useCustomSelectButtonStyles = makeStyles<Theme, StyleProps>(
  ({ spacing, palette }) => ({
    container: {
      width: ({ width }) => width || 230,
      height: 40,
      padding: 0,
      borderRadius: 8,
      border: ({ open }) =>
        `0.5px solid ${open ? palette.primary.main : TextColor.LightGrey}`
    },
    label: {
      fontSize: 14,
      color: TextColor.Grey,
      paddingRight: spacing(1),
      display: 'flex',
      fontWeight: 400,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    valueWrapper: {
      padding: spacing(2)
    },
    labelDetails: {
      fontSize: 14,
      fontWeight: 500
    },
    labelHighlight: {
      color: palette.primary.main
    },
    iconContainer: {
      display: 'flex',
      borderLeft: ({ noBorder }) =>
        noBorder ? 'unset' : `0.5px solid ${TextColor.LightGrey}`,
      width: 40,
      minWidth: 40,
      height: 40,
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 'auto',

      '& svg': {
        transform: ({ open }) =>
          `rotate(${open ? '270deg' : '90deg'}) scale(0.6) !important `,
        fill: ({ open }: { open: boolean }) =>
          `${open ? palette.primary.main : TextColor.Grey}`,
        transition: 'all 0.3s ease-in-out'
      }
    }
  })
);
