import { makeStyles, Typography } from '@material-ui/core';
import {
  Contact,
  TContact
} from '@setvi/shared/components/sdrawer-content/components/contact';
import { distances } from '@setvi/shared/styles';
import SInfiniteScroll from '../../../../sinfinite-scroll';
import { Recipient } from '../../../../../interfaces';
import { SortableContactProperties } from '../contact-filter';
import { isContactSelected } from '../utils';

interface AddressBookProps {
  contacts: TContact[];
  selectedContacts?: Recipient[];
  children?: JSX.Element;
  onContactClick?: (contact: TContact) => void;
  fetchMore?: () => void;
  sortingParameter?: SortableContactProperties;
}

const useAddressBookStyles = makeStyles(() => ({
  letter: {
    margin: `${distances.small}px 0px 0px ${distances.small}px`,
    fontWeight: 700
  }
}));

export const AddressBook = ({
  contacts,
  selectedContacts,
  children,
  onContactClick,
  fetchMore,
  sortingParameter = 'FirstName'
}: AddressBookProps) => {
  const styles = useAddressBookStyles();
  let selectedLetter = '';

  return (
    <SInfiniteScroll
      list={contacts}
      fetchMore={fetchMore}
      keyGetter={element => element?.Id || element?.Email}
      render={contact => {
        const isCheckboxDisabled = selectedContacts.some(
          ({ Email, Id }) => Email === contact.Email && Id !== contact.Id
        );
        let renderLetter = false;
        if (
          contact[sortingParameter] &&
          contact[sortingParameter]?.charAt(0).toUpperCase() !== selectedLetter
        ) {
          renderLetter = true;
          selectedLetter = contact[sortingParameter]?.charAt(0).toUpperCase();
        }
        return (
          <>
            {renderLetter && (
              <Typography className={styles.letter}>
                {selectedLetter}
              </Typography>
            )}
            <Contact
              contact={contact}
              checkbox={{
                checked: isContactSelected(contact, selectedContacts),
                onChange: () => {
                  onContactClick?.(contact);
                },
                disabled: isCheckboxDisabled,
                tooltip: isCheckboxDisabled
                  ? 'Contact with the same email already added'
                  : '',
                disableTooltip: !isCheckboxDisabled
              }}
            />
          </>
        );
      }}>
      {children}
    </SInfiniteScroll>
  );
};
