interface MinimalContact {
  Id?: number;
  Email: string;
  [key: string]: any;
}

export const isContactSelected = (
  contact: MinimalContact,
  selectedContacts: MinimalContact[]
): boolean =>
  selectedContacts.some(selectedContact => {
    if (contact?.Id) return contact?.Id === selectedContact?.Id;

    return contact?.Email === selectedContact?.Email && !selectedContact?.Id;
  });
