import { AxiosMethods } from '../../../../enums';
import { axiosHelper } from '../../../axios-instance';

export const downloadBackorderCSV = (accoundID: string) => ({
  queryKey: ['file_downloader', accoundID],
  queryFn: () =>
    axiosHelper({
      endpoint: `/order/backorders/csv/${accoundID}`,
      method: AxiosMethods.GET,
      baseURL: process.env.REVOPS_API_BASE_URL
    }),
  enabled: false
});
