import { Box } from '@material-ui/core';

interface DrawerListStateProps {
  title: string;
}

export const DrawerListState = ({ title }: DrawerListStateProps) => (
  <Box
    sx={{
      flex: 1,
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
    {title}
  </Box>
);
