/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';
import { Editor as TinyMCEEditor, EditorOptions } from 'tinymce';
import { EditorActions, KeyCode } from '../../enums';
import { viewIcon, removeIcon } from '../../components';
import { IHandleProductsOptions, SEditorType } from '../../interfaces';
import { Product } from '../../../../interfaces';
import { getProductCardAsHtml } from './heplers';

interface ProductProps {
  editorRef?: TinyMCEEditor | null;
  products: Product[];
  manufacturer: boolean;
}

export const selectedProductClassName = 'selected-product';
export const selectedProductLinkClassName = 'product-link';
export const selectedProductTitleClassName = 'product-title';

export const useEditorProducts = ({
  products,
  editorRef,
  manufacturer
}: ProductProps) => {
  // We have a lot links with old logic so this is backup to recognize old links and replace them with new ones
  useEffect(() => {
    if (editorRef && products?.length) {
      const content = editorRef?.getBody();

      const bodyProducts = content?.querySelectorAll(
        `.${selectedProductClassName}`
      );
      const arrayFromNodeList = Array.from(bodyProducts);

      products?.forEach(i => {
        if (arrayFromNodeList.find(j => j.id === i._id)) return;

        editorRef?.execCommand(
          'mceInsertContent',
          false,
          `<p>${getProductCardAsHtml(i, manufacturer)}</p>`
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef, products]);

  const deleteProduct = (
    editorNode: TinyMCEEditor,
    preventDefault?: () => void
  ) => {
    const editor = editorNode;
    const selection = editor?.selection;
    const selectedNode = selection?.getNode();

    const clickedOnProductName =
      (selectedNode?.tagName === 'P' &&
        selectedNode?.classList.contains(selectedProductTitleClassName)) ||
      (selectedNode?.tagName === 'A' &&
        selectedNode?.classList.contains(selectedProductLinkClassName));

    const prevElement = selectedNode?.previousSibling;
    const parent = selectedNode?.parentElement?.parentElement?.parentElement;

    const currentElement = clickedOnProductName ? parent : prevElement;

    if (currentElement?.nodeName === 'DIV') {
      const ID = (currentElement as any)?.id;
      if (!ID) return;

      const hasId = editor?.getBody()?.hasAttribute('id');
      if (!hasId) return;
      editor?.execCommand('mceInsertContent', false, '');

      const node = editor?.getBody()?.querySelector(`#${ID}`);
      if (node) node.remove();

      editor?.execCommand('mceInsertContent', false, '');

      preventDefault?.();
    }
  };

  const handleCursorIsInsideProduct = (editor: SEditorType) => {
    const bookmark = editor.selection.getBookmark();
    editor.selection.moveToBookmark(bookmark);

    // Check if the cursor is inside a product
    const node = editor.selection.getNode();

    if (
      node.nodeName === 'DIV' &&
      node?.classList.contains(selectedProductClassName)
    ) {
      const range = editor.selection.getRng();
      // Check if cursor is at the beginning  of the product
      const start = range.startOffset === 1 && range.endOffset === 1;
      if (start) {
        // Move the cursor to the beginning  of the product
        const newText = editor.getDoc().createTextNode(' ');
        node.parentNode.insertBefore(newText, node);
        range.setStartBefore(newText);
        range.setEndBefore(node);
        editor.selection.setRng(range);
      } else {
        // Move the cursor to the  ending of the product
        range.setEndAfter(node);
        range.collapse(false);
        editor.selection.setRng(range);
        editor.execCommand('mceInsertContent', false, '');
      }
    }
  };

  const insertProduct = (newProduct: Product) => {
    if (!newProduct || !editorRef) return;

    const selection = editorRef?.selection;
    const selectedNode = selection?.getNode();

    if (
      selectedNode?.tagName === 'DIV' &&
      selectedNode.classList.contains(selectedProductClassName)
    )
      handleCursorIsInsideProduct(editorRef);

    editorRef.execCommand(
      'mceInsertContent',
      false,
      `<p>${getProductCardAsHtml(newProduct, manufacturer)}</p>`
    );
  };

  const viewProduct = (editorNode: TinyMCEEditor) => {
    const selection = editorRef?.selection || editorNode?.selection;

    const linkNode = selection?.getNode();
    const link = linkNode?.getAttribute('data-product-href');

    window.open(link, '_blank').focus();
  };

  const handleProducts = (
    action: EditorActions,
    { product, editorNode }: IHandleProductsOptions
  ) => {
    switch (action) {
      case EditorActions.DELETE:
        deleteProduct(editorNode);
        break;
      case EditorActions.INSERT:
        insertProduct(product);
        break;
      default:
        viewProduct(editorNode);
    }
  };

  // Remove whole product for backspace and delete key
  const handleKeyDown = async (
    e: {
      keyCode: number;
      ctrlKey: boolean;
      metaKey: boolean;
      preventDefault: () => void;
    },
    editorNode: TinyMCEEditor
  ) => {
    if (e.keyCode === KeyCode.BACKSPACE || e.keyCode === KeyCode.DELETE) {
      await deleteProduct(editorNode, e.preventDefault);
    } else {
      const ignoredKeyCodes = [37, 38, 39, 40]; //   Arrow keys
      if (e.ctrlKey || e.metaKey || ignoredKeyCodes.includes(e.keyCode)) return;
      handleCursorIsInsideProduct(editorNode);
    }
  };

  const initProductsOptions: Partial<
    Omit<EditorOptions, 'selector' | 'target'>
  > = {
    setup: (editor: TinyMCEEditor) => {
      editor.on('click', e => {
        if (e.target.classList.contains(selectedProductLinkClassName)) {
          e.preventDefault();
        }
      });

      editor.on('keydown', e => {
        handleKeyDown(e, editor);
      });

      editor.ui.registry.addContextToolbar('productFirstContextToolbar', {
        predicate: node =>
          node?.classList.contains(selectedProductLinkClassName),
        items: 'viewProduct deleteProduct',
        position: 'node',
        scope: 'node'
      });

      editor.ui.registry.addContextToolbar('productSecondContextToolbar', {
        predicate: node =>
          node?.classList.contains(selectedProductTitleClassName),
        items: 'deleteProduct',
        position: 'node',
        scope: 'node'
      });

      editor.ui.registry.addIcon('view', viewIcon);
      editor.ui.registry.addIcon('trash', removeIcon);

      editor.ui.registry.addButton('viewProduct', {
        icon: 'view',
        text: 'View',
        tooltip: 'View Product',
        onAction: () =>
          handleProducts(EditorActions.VIEW, {
            editorNode: editor
          })
      });

      editor.ui.registry.addButton('deleteProduct', {
        icon: 'trash',
        text: 'Delete',
        tooltip: 'Delete Link',
        onAction: () =>
          handleProducts(EditorActions.DELETE, {
            editorNode: editor
          })
      });
    }
  };

  return {
    initProductsOptions,
    handleProducts
  };
};
