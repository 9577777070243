import { Box, Skeleton } from '@mui/material';

interface ContactsLoaderProps {
  size?: number;
}

export const ContactsLoader = ({ size = 10 }: ContactsLoaderProps) => (
  <Box display="grid" gap="10px" px={1}>
    {Array(size)
      .fill(0)
      .map((_, i) => (
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          display="grid"
          gridTemplateColumns="36px 1fr 20px"
          alignItems="center"
          gap="10px">
          <Box>
            <Skeleton variant="circular" width="36px" height="36px" />
          </Box>
          <Box>
            <Skeleton variant="text" />
            <Skeleton variant="text" width="90%" />
            <Skeleton variant="text" width="70%" />
          </Box>
          <Box>
            <Skeleton variant="rounded" width="20px" height="20px" />
          </Box>
        </Box>
      ))}
  </Box>
);
