import { useRef, useState } from 'react';
import { SButton, SSearchInput, SText } from '@setvi/shared/components';
import { Box, Divider, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Location } from '../location-select/location';
import { State } from '../../../../../../services/react-query/query/contacts/types';
import { DrawerListState } from '../../../../components/list-state';
import { useStates } from './useStates';

interface StateSelectProps {
  selectedStates: State['StateCode'][];
  setSelectedStateCodes: (states: State['StateCode'][]) => void;
  handleClose: () => void;
}

export const StateSelect = ({
  selectedStates = [],
  setSelectedStateCodes,
  handleClose
}: StateSelectProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [selStates, setSelStates] =
    useState<State['StateCode'][]>(selectedStates);

  const { states, statesLoading, setStateSearchTerm, stateSearchTerm } =
    useStates();

  const handleSelect = (checked: boolean, stateCode: State['StateCode']) => {
    checked
      ? setSelStates(prevStates => [...prevStates, stateCode])
      : setSelStates(selStates.filter(code => code !== stateCode));
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" minHeight={0}>
      <Box display="flex" alignItems="center" m={3} mb={2}>
        <SText title="Filter By State" weight="bold" />
        <Box ml={2}>
          <SButton
            variant="text"
            onClick={() => {
              // @ts-ignore
              ref?.current?.clear();
              setStateSearchTerm('');
              setSelStates([]);
            }}
            style={{ padding: 0, fontWeight: 400, height: 20 }}>
            Clear all
          </SButton>
        </Box>
        <Box ml="auto">
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Divider />
      </Box>
      <Box my={2} mx={3}>
        <SSearchInput
          ref={ref}
          defaultValue={stateSearchTerm}
          onChange={setStateSearchTerm}
        />
      </Box>
      <Box sx={{ flex: 1, marginLeft: 10, overflow: 'auto' }}>
        {statesLoading && <DrawerListState title="Loading..." />}
        {!statesLoading && states?.length === 0 && (
          <DrawerListState title="No States Found" />
        )}
        {states?.map(state => (
          <Location
            key={state.StateCode}
            title={`${state.State}, ${state.StateCode}, ${state.Country}`}
            checked={selStates.some(code => code === state.StateCode)}
            onChange={e => handleSelect(e.target.checked, state.StateCode)}
          />
        ))}
      </Box>
      <Divider />
      <Box p={3}>
        <SButton
          fullWidth
          onClick={() => {
            // @ts-ignore
            ref?.current?.clear();
            setStateSearchTerm('');
            setSelectedStateCodes(selStates);
          }}>
          Apply
        </SButton>
      </Box>
    </Box>
  );
};
