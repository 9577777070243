export const hasAtLeastOneUpperCase = (password: string) =>
  /[A-Z]/.test(password);
export const hasAtLeastOneNumber = (password: string) => /[0-9]/.test(password);
export const hasAtLeastOneSpecialCharacter = (password: string) =>
  /[$#%&{}!]/.test(password);

export const passwordValidation = (password: string) => {
  if (password?.length < 6) return 0;

  const securePassword = [
    hasAtLeastOneUpperCase(password),
    hasAtLeastOneNumber(password),
    hasAtLeastOneSpecialCharacter(password),
    password.length >= 8
  ];

  return securePassword.filter(val => val).length;
};
