import { useInfiniteQuery } from '@tanstack/react-query';

import { useCallback, useMemo, useState } from 'react';
import { getSalesforceContacts } from '../../../../../services';
import {
  Account,
  City,
  State
} from '../../../../../services/react-query/query/contacts/types';

interface useCrmContactsProps {
  accountIds: Account['Id'][];
  cities: City[];
  states: State['StateCode'][];
}

const CONTACTS_LIMIT = 20;
export const useCrmContacts = ({
  accountIds,
  cities,
  states
}: useCrmContactsProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const queryVariables = useMemo(
    () => ({
      pageNumber: 1,
      pageSize: CONTACTS_LIMIT,
      searchString: searchTerm,
      states,
      cities: cities.map(c => c.City),
      accounts: accountIds
    }),
    [searchTerm, accountIds, states, cities]
  );

  const {
    data: contacts,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(getSalesforceContacts(queryVariables));

  const fetchMoreContacts = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return {
    contacts: contacts?.pages?.map(page => page?.Data).flat(1) || [],
    isLoading: isLoading || isFetching,
    setSearchTerm,
    fetchMoreContacts,
    searchTerm
  };
};
