import {
  QueryFunctionContext,
  UseInfiniteQueryOptions
} from '@tanstack/react-query';

import { axiosHelper } from '@setvi/shared/services';
import { BaseQueryResponse } from '@setvi/shared/interfaces';
import { AdminUsersApi, AxiosMethods } from '@setvi/shared/enums';

import {
  AdminUsersQueryResponse,
  AdminUsersQueryVariables,
  UserDetails
} from './Types';

export enum AdminUsersQueryKey {
  ADMIN_USERS = 'ADMIN_USERS',
  ADMIN_USER = 'ADMIN_USER'
}

export const getAdminUsersQuery = (params: AdminUsersQueryVariables) => ({
  queryKey: [AdminUsersQueryKey.ADMIN_USERS, params],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminUsersApi.GetUsers,
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params
    }),
  select: (data: BaseQueryResponse<AdminUsersQueryResponse>) => data?.Data,
  refetchOnWindowFocus: false
});

export const getAdminUserQuery = (id: string) => ({
  queryKey: [AdminUsersQueryKey.ADMIN_USER, id],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminUsersApi.GetUser.replace(':userId', id),
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  select: (data: BaseQueryResponse<UserDetails>) => data?.Data,
  refetchOnWindowFocus: false
});

export const getAdminUsersInfiniteQuery = (
  params: AdminUsersQueryVariables
): UseInfiniteQueryOptions<BaseQueryResponse<AdminUsersQueryResponse>> => ({
  queryKey: [AdminUsersQueryKey.ADMIN_USERS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: AdminUsersApi.GetUsers,
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params: {
        ...params,
        pageNumber: data.pageParam || params.pageNumber
      }
    }),
  getNextPageParam: (
    lastPage: BaseQueryResponse<AdminUsersQueryResponse>,
    allPages: BaseQueryResponse<AdminUsersQueryResponse>[]
  ) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Items?.length,
      0
    );
    const currentPageNumber = total / params.pageSize;
    return lastPage?.Data?.Items?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});
