import { SBase } from '@setvi/shared/components';
import { useDialog } from '@setvi/shared/providers';
import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { ROUTES } from 'enumsV2';
import { emailRegex } from 'Utils/regex';
import { UserInformation } from 'pages/settings/subpages/general/user-information';

interface IUserInfoDialogProvider {
  children: ReactNode;
}

export const UserInfoDialogProvider = ({
  children
}: IUserInfoDialogProvider) => {
  const location = useLocation();
  const { openDialog, closeDialog } = useDialog();
  const { user } = useAppContext();
  const [tab] = useQueryParam('tab');

  const openUserInformationDialog = () => {
    openDialog(
      <SBase width={500}>
        <UserInformation
          onSubmitted={closeDialog}
          label="Please populate all fields to continue using the application"
          fullWidthSubmitButton
          submitButtonText="Save"
        />
      </SBase>,
      {
        preventClose: true
      }
    );
  };

  useEffect(() => {
    if (
      !user ||
      location.pathname === ROUTES.VERIFY_EMAIL ||
      location.pathname === ROUTES.LOGIN ||
      location.pathname === ROUTES.VERIFY_PASSWORD ||
      (location.pathname === ROUTES.HOME && !tab)
    )
      return;

    if (
      window.location.origin.includes('middleby') &&
      (!user?.FirstName ||
        !user?.LastName ||
        !user?.ContactNumber ||
        emailRegex.test(user.FirstName) ||
        emailRegex.test(user.LastName))
    )
      openUserInformationDialog();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, openDialog, user, tab]);
  return <>{children}</>;
};
