import { useRef, useState } from 'react';
import {
  SButton,
  SInfiniteScroll,
  SSearchInput,
  SText
} from '@setvi/shared/components';
import { Box, Divider, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Location } from '../location-select/location';
import {
  City,
  State
} from '../../../../../../services/react-query/query/contacts/types';
import { DrawerListState } from '../../../../components/list-state';
import { useCities } from './useCities';

interface CitySelectProps {
  selectedCities: City[];
  selectedStateCodes: State['StateCode'][];
  setSelectedCities: (cities: City[]) => void;
  handleClose: () => void;
}

export const CitySelect = ({
  selectedCities = [],
  selectedStateCodes = [],
  setSelectedCities,
  handleClose
}: CitySelectProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [selCities, setSelCities] = useState<City[]>(selectedCities);

  const {
    cities,
    citiesLoading,
    fetchMoreCities,
    searchString,
    setSearchString
  } = useCities({ selectedStateCodes });

  const handleSelect = (checked: boolean, city: City) => {
    if (checked) {
      setSelCities(prevCities => [...prevCities, city]);
    } else {
      setSelCities(
        selCities.filter(
          c => `${c.City}${c.State}` !== `${city.City}${city.State}`
        )
      );
    }
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" minHeight={0}>
      <Box display="flex" alignItems="center" m={3} mb={2}>
        <SText title="Filter By City" weight="bold" />
        <Box ml={2}>
          <SButton
            variant="text"
            onClick={() => {
              // @ts-ignore
              ref?.current?.clear();
              setSearchString('');
              setSelCities([]);
            }}
            style={{ padding: 0, fontWeight: 400, height: 20 }}>
            Clear all
          </SButton>
        </Box>
        <Box ml="auto">
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Divider />
      </Box>
      <Box my={2} mx={3}>
        <SSearchInput
          ref={ref}
          defaultValue={searchString}
          onChange={setSearchString}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          marginLeft: 10,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Box>
          <SInfiniteScroll
            list={cities}
            keyGetter={c => `${c.City}${c.State}`}
            fetchMore={fetchMoreCities}
            render={city => (
              <Location
                key={`${city.City}${city.State}`}
                title={`${city.City}, ${city.State}, ${city.StateCode}`}
                onChange={e => handleSelect(e.target.checked, city)}
                checked={selCities.some(
                  c => `${c.City}${c.State}` === `${city.City}${city.State}`
                )}
              />
            )}
          />
        </Box>
        {citiesLoading && <DrawerListState title="Loading..." />}
        {!citiesLoading && cities?.length === 0 && (
          <DrawerListState title="No Cities Found" />
        )}
      </Box>
      <Divider />
      <Box p={3}>
        <SButton
          fullWidth
          onClick={() => {
            setSelectedCities(selCities);
            setSearchString('');
            // @ts-ignore
            ref?.current?.clear();
          }}>
          Apply
        </SButton>
      </Box>
    </Box>
  );
};
