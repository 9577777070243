import { memo } from 'react';
import { TreeView, TreeViewProps, TreeItemProps } from '@material-ui/lab';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import STreeNode, { STreeNodeProps } from './node';

export interface STreeItemProps extends Omit<TreeItemProps, 'nodeId'> {
  nodeId?: string;
}

interface STreeProps<T>
  extends Omit<STreeNodeProps<T>, 'node' | 'nodeKeyGetter'> {
  nodes: T[];
  keyGetter: (item: T) => string | number;
  treeViewProps?: TreeViewProps;
  treeItemProps?: STreeItemProps;
  selected?: T;
}

const STree = <T,>({
  nodes,
  selected,
  keyGetter,
  renderNodeLabel,
  onNodeClick,
  onLabelClick,
  nodeChildrenGetter,
  onIconClick,
  treeViewProps,
  treeItemProps
}: STreeProps<T>) => (
  <TreeView
    defaultCollapseIcon={
      treeViewProps?.defaultCollapseIcon || <ExpandMoreRoundedIcon />
    }
    defaultExpandIcon={
      treeViewProps?.defaultExpandIcon || <ChevronRightRoundedIcon />
    }
    selected={selected && (keyGetter(selected).toString() as any)}
    {...treeViewProps}>
    {nodes?.map(node => (
      <STreeNode
        key={keyGetter(node).toString()}
        node={node}
        nodeKeyGetter={keyGetter}
        renderNodeLabel={renderNodeLabel}
        nodeChildrenGetter={nodeChildrenGetter}
        onNodeClick={onNodeClick}
        onLabelClick={onLabelClick}
        onIconClick={onIconClick}
        {...treeItemProps}
      />
    ))}
  </TreeView>
);

export default memo(STree) as typeof STree;
