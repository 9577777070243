export const newlineRegex = /\n/g;
export const htmlTags = /<[^>]*>/g;
export const removeNbsp = /&nbsp;/g;

export const mailMergeHtmlVariables = /<label class="mce-mergetag">|<\/label>/g;

export const linkAddress =
  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})?(\/.*)?$/;

export const contentEditableTag = /<span>|<\/span>/g;

export const isYoutubeLink =
  /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([a-zA-Z0-9_-]+)/;
export const isVimeoLink =
  /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com)\/(?:video\/)?([0-9]+)/;

export const isPhone = /^[0-9+]*$/;
// Number in this order ###-###-#### (dashes optional)
export const isPhoneWithOptionalDashes = /^[\d-]+$/;
// accept all kind of phone numbers
export const validPhoneNumber =
  /^(\+?\d{1,2}\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/;
// password that includes at least one uppercase letter and one digit and min length is 8. It can contain special characters
export const validPassword = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
export const validEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const immutableLink = (placeholder: string) =>
  new RegExp(`<a.*?href="${placeholder}".*?>(.*?)</a>`, 'g');

export const cleanHtml = (html = '') =>
  html
    ?.trim()
    .replace(htmlTags, '')
    .replace(removeNbsp, '')
    .replace(newlineRegex, '');
