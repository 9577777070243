import { useState } from 'react';
import { SInfiniteScroll } from '@setvi/shared/components';
import { HubspotCompany } from '../../../../../../../services/react-query/query/contacts/types';
import { DrawerListState } from '../../../../../components/list-state';
import { FilterContainer, ListItem, Loader } from '../components';
import { useCompanies } from './useCompanies';
import { SliderContent } from '../..';

interface CompanySelectProps {
  selectedCompanies: HubspotCompany[];
  setSelectedCompanies: (companies: HubspotCompany[]) => void;
  handleClose: () => void;
}

export const CompanyFilter = ({
  selectedCompanies = [],
  setSelectedCompanies,
  handleClose
}: CompanySelectProps) => {
  const [selCompanies, setSelCompanies] =
    useState<HubspotCompany[]>(selectedCompanies);

  const {
    companies,
    searchTerm,
    isLoading,
    fetchMoreCompanies,
    setSearchTerm
  } = useCompanies();

  const handleSelect = (checked: boolean, company: HubspotCompany) => {
    if (checked) {
      setSelCompanies(prevAccounts => [...prevAccounts, company]);
    } else {
      setSelCompanies(
        selCompanies.filter(
          comp => comp.AssociatedCompanyId !== company.AssociatedCompanyId
        )
      );
    }
  };

  return (
    <FilterContainer
      filterBy={SliderContent.Company}
      selected={!!selCompanies.length}
      handleSearch={setSearchTerm}
      handleClear={() => {
        setSearchTerm('');
        setSelCompanies([]);
      }}
      handleApply={() => {
        setSearchTerm('');
        setSelectedCompanies(selCompanies);
      }}
      handleClose={handleClose}>
      <SInfiniteScroll
        list={companies}
        keyGetter={el =>
          `${el.AssociatedCompanyId}${el.CompanyName}${el.CustomerId}`
        }
        fetchMore={fetchMoreCompanies}
        render={company => (
          <ListItem
            label={company.CompanyName}
            searchTerm={searchTerm}
            isSelected={selCompanies.some(
              comp => comp.AssociatedCompanyId === company.AssociatedCompanyId
            )}
            onSelect={checked => handleSelect(checked, company)}
          />
        )}
      />
      {isLoading && <Loader />}
      {!isLoading && companies?.length === 0 && (
        <DrawerListState title="No Companies Found" />
      )}
    </FilterContainer>
  );
};
