import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { getHubspotStates } from '../../../../../../../services';
import { CONTACT_FILTER_LIMIT } from '../company/useCompanies';

export const useStates = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);

  const { data, isLoading, isFetching } = useQuery(getHubspotStates());

  const states = useMemo(() => {
    const filteredStates =
      data?.Data?.filter(
        i => i.State.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      ) || [];

    return filteredStates?.splice(0, page * CONTACT_FILTER_LIMIT);
  }, [data, page, searchTerm]);

  const handleSearch = (search: string) => {
    setPage(1);
    setSearchTerm(search);
  };

  const fetchMore = useCallback(() => {
    const hasMore = Math.ceil(data?.Data?.length / CONTACT_FILTER_LIMIT) > page;

    if (hasMore) {
      setFetchMoreLoading(true);

      setTimeout(() => {
        setPage(page + 1);
        setFetchMoreLoading(false);
      }, 300);
    }
  }, [data?.Data?.length, page]);

  return {
    states,
    isLoading: isLoading || isFetching || fetchMoreLoading,
    searchTerm,
    setSearchTerm,
    handleSearch,
    fetchMore
  };
};
