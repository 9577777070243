import { Box, ButtonBase, makeStyles } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { SDropdownMenu } from '@setvi/shared/components';
import Content from './Content';
import useDropdownDetails from './useDropdownDetails';

const useStyles = makeStyles(theme => ({
  menuBoxWidth: {
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  }
}));

const StyledBox = withStyles(({ spacing }) => ({
  root: {
    borderRadius: 15,
    margin: `${spacing(1)}px ${spacing(0)}px`,
    padding: spacing(1, 2, 1, 3)
  }
}))(Box);

export const ProfileDropdown = () => {
  const { menuItems } = useDropdownDetails();
  const classes = useStyles();

  return (
    <SDropdownMenu menuItems={menuItems}>
      <StyledBox
        display="flex"
        alignItems="center"
        component={ButtonBase}
        className={classes.menuBoxWidth}>
        <Content />
        <ArrowDropDown />
      </StyledBox>
    </SDropdownMenu>
  );
};
