import { axiosHelper } from '@setvi/shared/services';
import { AxiosMethods, AdminUsersApi } from '@setvi/shared/enums';

import { AdminUserValues } from 'pages/admin/pages/users/interfaces';

import { ChangeUsersStatusParams } from './Types';

export const createUserMutation = () => ({
  mutationFn: (body: Partial<AdminUserValues>) =>
    axiosHelper({
      endpoint: AdminUsersApi.CreateUser,
      method: AxiosMethods.POST,
      baseURL: process.env.ADMIN_API_BASE_URL,
      body
    })
});

export const updateUserMutation = () => ({
  mutationFn: (body: Partial<AdminUserValues>) =>
    axiosHelper({
      endpoint: AdminUsersApi.UpdateUser,
      method: AxiosMethods.PUT,
      baseURL: process.env.ADMIN_API_BASE_URL,
      body
    })
});

export const changeUsersStatusMutation = () => ({
  mutationFn: ({ userId, statusId }: ChangeUsersStatusParams) =>
    axiosHelper({
      endpoint: AdminUsersApi.ChangeUsersStatus.replace(
        ':statusId',
        statusId.toString()
      ),
      method: AxiosMethods.PATCH,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params: {
        userId
      }
    })
});

export const deleteUsersMutation = () => ({
  mutationFn: (userId: number[]) =>
    axiosHelper({
      endpoint: AdminUsersApi.DeleteUsers,
      method: AxiosMethods.DELETE,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params: {
        userId
      }
    })
});

export const resetUserPasswordMutation = () => ({
  mutationFn: (id: number) =>
    axiosHelper({
      endpoint: AdminUsersApi.ResetUserPassword.replace(
        ':userId',
        id.toString()
      ),
      baseURL: process.env.ADMIN_API_BASE_URL,
      method: AxiosMethods.PATCH
    })
});

export const changeUserPasswordMutation = () => ({
  mutationFn: (body: { UserId: number; Password: string }) =>
    axiosHelper({
      endpoint: AdminUsersApi.ChangeUserPassword,
      method: AxiosMethods.PATCH,
      baseURL: process.env.ADMIN_API_BASE_URL,
      body
    })
});
