import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const SuggestedContactsLoader = () => (
  <Box p={2}>
    {Array.from({ length: 3 }).map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Grid item xs={12} key={index}>
        <Grid container spacing={2}>
          <Grid item xs={1} justifyContent="center" alignContent="center">
            <Skeleton variant="circle" width={40} height={40} />
          </Grid>
          <Grid item xs={10} justifyContent="center" alignContent="center">
            <Skeleton variant="text" width="70%" />
            <Skeleton variant="text" width="50%" />
            <Skeleton variant="text" width="60%" />
          </Grid>
          <Grid item xs={1} justifyContent="center" alignContent="center">
            <Skeleton variant="rect" width={32} height={32} />
          </Grid>
        </Grid>
      </Grid>
    ))}
  </Box>
);
