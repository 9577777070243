import { createContext, useContext } from 'react';
import { DrawerContent } from '@setvi/shared/enums';
import { Recipient, Link, Product } from '@setvi/shared/interfaces';
import { ComposeEmailValues } from '../hook';

const initialValues = {
  sendTo: [] as Recipient[],
  textBody: '',
  subject: '',
  body: ''
};

export interface openComposeProps {
  linksData?: Link;
  drawerContent?: DrawerContent;
  emailBody?: string;
  products?: Product[];
}

export type ComposeEmailContextType = {
  values: ComposeEmailValues;
  links: Array<Link>;
  editorNode: any;
  open: ({ linksData, drawerContent }: openComposeProps) => void;
  close: () => void;
};

const contextDefaultValues: ComposeEmailContextType = {
  values: initialValues,
  links: [],
  editorNode: null,
  open: () => {},
  close: () => {}
};

export const ComposeEmailContext =
  createContext<ComposeEmailContextType>(contextDefaultValues);

export const useComposeEmail = () => {
  const context = useContext(ComposeEmailContext);

  // if `undefined`, throw an error
  if (context === undefined)
    throw new Error('useComposeEmail was used outside of its Provider');

  return context;
};
