import React from 'react';
import {
  Box,
  Button as MUIButton,
  ButtonProps,
  Divider,
  withStyles
} from '@material-ui/core';

const Button = withStyles({
  startIcon: {
    position: 'relative',
    '&:after': {
      content: '""',
      display: (props: { active?: boolean }) =>
        props.active ? 'block' : 'none',
      position: 'absolute',
      borderRadius: '50%',
      height: 7,
      width: 7,
      right: 0,
      top: 0,
      backgroundColor: '#fa5a5b'
    }
  }
})(({ active, ...props }: ButtonProps & { active?: boolean }) => (
  <MUIButton {...props} />
));

interface NavigationItem {
  label: string;
  startIcon: React.ReactNode;
  onClick?: () => void;
  active?: boolean;
}

interface FloatingNavigationProps {
  items: NavigationItem[];
}

const FloatingNavigationWrapper = withStyles(() => ({
  root: {
    position: 'absolute',
    bottom: 10,
    left: '50%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    transform: 'translateX(-50%)',
    backgroundColor: '#01294B',
    borderRadius: 50
  }
}))(Box);

export const FloatingNavigation = ({ items }: FloatingNavigationProps) => (
  <FloatingNavigationWrapper>
    {items.map((item, index) => (
      <Box key={item.label} display="flex">
        <Button
          startIcon={item.startIcon}
          key={item.label}
          active={item.active}
          onClick={item.onClick}
          style={{ color: '#fff' }}>
          {item.label}
        </Button>
        {index + 1 < items?.length && (
          <Divider
            style={{ backgroundColor: '#fff' }}
            flexItem
            orientation="vertical"
          />
        )}
      </Box>
    ))}
  </FloatingNavigationWrapper>
);
