export enum TextColor {
  Black = '#171725',
  Grey = '#696974',
  DarkGrey = '#44444F',
  LightGrey = '#92929D',
  Blue = '#1E96FC',
  Green = '#23A566',
  Red = '#FC5A5A',
  Orange = '#FF9F29'
}
