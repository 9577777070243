import { useQuery } from '@tanstack/react-query';

import { SortType } from '@setvi/shared/enums';

import { getSuggestedContacts } from 'Services';
import { Contact } from 'Services/Query/Contacts/Types';

interface useSuggestedContactsProps {
  search: string;
}

const TOTAL_SUUGESTED_CONTACTS = 5;

export const useSuggestedContacts = ({ search }: useSuggestedContactsProps) => {
  const {
    data: suggestedContacts,
    isLoading,
    isFetching
  } = useQuery({
    ...getSuggestedContacts({
      search,
      count: TOTAL_SUUGESTED_CONTACTS,
      sortBy: 'Email',
      orderBy: SortType.ASC
    }),
    enabled: search.length > 0
  });

  return {
    suggestedContacts: suggestedContacts?.Data?.Contacts as Contact[],
    isLoading: isLoading || isFetching
  };
};
