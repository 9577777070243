import { Box, TextField, withStyles } from '@material-ui/core';

export const Container = withStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    boxShadow: 'none',
    flexDirection: 'column',
    backgroundColor: 'transparent'
  }
}))(Box);

export const FieldContainer = withStyles(() => ({
  root: {
    boxShadow: 'none',
    borderRadius: '8px',
    position: 'relative'
  }
}))(Box);

export const CustomField = withStyles(() => ({
  root: {
    height: 'auto',
    minHeight: '48px',
    borderRadius: '8px'
  }
}))(TextField);
