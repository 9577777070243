import { useEffect, useRef, useState } from 'react';
import { Field, FieldProps } from 'formik';
import { styled, useTheme } from '@material-ui/core/styles';
import { Box, FormHelperText } from '@material-ui/core';

import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

import { SSelectDropdown, SingleSelectList } from '../../sselect';
import SLabel from '../../../sui/slabel';
import SText from '../../../sui/stext';
import SButton from '../../../sbutton';

export interface Option {
  id: number;
  label: string;
  value: string;
  htmlElement?: JSX.Element;
}

interface FormikSelectProps {
  name: string;
  label?: string;
  width?: number;
  required?: boolean;
  options: Option[];
  placeholder?: string;
  customErrors?: any;
  onChange?: (value: string | number) => void;
}

const SelectContainer = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
  gridGap: 12,

  '& button': {
    height: 55
  }
});

const SFormikSelect = ({
  name,
  label,
  width,
  options,
  required,
  placeholder,
  customErrors,
  onChange
}: FormikSelectProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [widthState, setWidthState] = useState<number>(450);
  const ref = useRef<HTMLInputElement>(null);
  const { palette } = useTheme();

  useEffect(() => {
    setWidthState(ref?.current?.offsetWidth || 450);
  }, [ref?.current?.offsetWidth]);

  return (
    <Field name={name}>
      {({ meta, form }: FieldProps) => (
        <SelectContainer
          // @ts-ignore
          ref={ref}>
          <SLabel title={label} required={required} variant="body2" />

          <SSelectDropdown
            width={width || widthState}
            anchorElement={anchorEl}
            onClose={() => {
              setAnchorEl(null);
            }}
            placement="bottom-start"
            anchor={
              <SButton
                variant="outlined"
                onClick={e => setAnchorEl(e.currentTarget)}
                fullWidth
                endIcon={
                  <KeyboardArrowDownRoundedIcon
                    htmlColor={anchorEl ? palette.primary.main : '#696974'}
                    style={{
                      transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s'
                    }}
                  />
                }
                style={{
                  justifyContent: 'space-between',
                  fontSize: 16,
                  fontWeight: 400,
                  color: meta?.value ? '#000' : '#A9A9AF',
                  borderColor: anchorEl ? palette.primary.main : '#E2E2EA'
                }}>
                {options?.find(o => o?.value === meta?.value)?.label ||
                  placeholder}
              </SButton>
            }
            content={
              <Box maxHeight={220} overflow="auto">
                <SingleSelectList
                  list={options}
                  keyGetter={option => option.id}
                  displayValueGetter={option => (
                    <Box width="100%" display="flex" flexDirection="column">
                      {option?.htmlElement || (
                        <SText>{option?.label || ''}</SText>
                      )}
                    </Box>
                  )}
                  onSelect={option => {
                    setAnchorEl(null);
                    const sameOption = meta?.value === option?.value;
                    // We force user to select some option from dropdown when this field is required
                    const currValue =
                      !required && sameOption ? '' : option?.value;

                    form.setFieldValue(name, currValue);
                    onChange?.(option?.value);
                  }}
                  isSelected={c => c?.value === meta?.value}
                />
              </Box>
            }
          />
          <FormHelperText error>
            {(meta.touched && Boolean(meta.error)) ||
              (Boolean(customErrors?.[name]) &&
                (meta.error || customErrors?.[name]))}
          </FormHelperText>
        </SelectContainer>
      )}
    </Field>
  );
};

export default SFormikSelect;
