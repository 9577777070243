import { Box, withStyles } from '@material-ui/core';
import { Skeleton as MUISkeleton } from '@material-ui/lab';
import { ItemContainer } from '../list-item';
import { CONTACT_FILTER_LIMIT } from '../../company/useCompanies';

const Skeleton = withStyles({
  root: {
    borderRadius: '4px',
    flexShrink: 0
  }
})(MUISkeleton);

export const Loader = () => (
  <Box>
    {Array(CONTACT_FILTER_LIMIT)
      .fill(null)
      .map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ItemContainer key={index}>
          <Skeleton variant="rect" width={20} height={20} />
          <Skeleton width="100%" />
        </ItemContainer>
      ))}
  </Box>
);
