import { memo } from 'react';
import { SDropdownMenu } from '@setvi/shared/components';
import { Resource } from '@setvi/shared/interfaces';
import { ActionProps, DropdownItemsType, useDropdownResource } from './hooks';

interface DropdownMenuProps {
  item: Resource;
  dropdownItems?: DropdownItemsType;
  refetchLoading?: boolean;
  onAction?: ({ type, resource }: ActionProps) => void;
  handleCloseDropdown?: () => void | null;
}

const DropdownMenu = ({
  item,
  dropdownItems,
  refetchLoading,
  onAction,
  handleCloseDropdown
}: DropdownMenuProps) => {
  const { createDropdownMenu } = useDropdownResource(refetchLoading, onAction);

  return (
    <SDropdownMenu
      menuItems={createDropdownMenu(item, dropdownItems, handleCloseDropdown)}
    />
  );
};

export default memo(DropdownMenu);
