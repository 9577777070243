import { useState } from 'react';
import { useStates } from './useStates';
import { FilterContainer, ListItem, Loader } from '../components';
import SInfiniteScroll from '../../../../../../sinfinite-scroll';
import { DrawerListState } from '../../../../../components/list-state';
import { SliderContent } from '../..';
import { HubspotState } from '../../../../../../../services/react-query/query/contacts/types';

interface CityFilterProps {
  selectedStates: any[];
  setSelectedStates: (states: any[]) => void;
  handleClose: () => void;
}

export const StateFilter = ({
  selectedStates,
  setSelectedStates,
  handleClose
}: CityFilterProps) => {
  const [localSelectedStates, setLocalSelectedStates] =
    useState<HubspotState[]>(selectedStates);

  const {
    states,
    searchTerm,
    isLoading,
    fetchMore,
    handleSearch,
    setSearchTerm
  } = useStates();

  const handleSelect = (checked: boolean, city: HubspotState) => {
    if (checked) {
      setLocalSelectedStates(prev => [...prev, city]);
    } else {
      setLocalSelectedStates(
        localSelectedStates.filter(i => i.State !== city.State)
      );
    }
  };

  return (
    <FilterContainer
      filterBy={SliderContent.State}
      selected={!!localSelectedStates.length}
      handleSearch={handleSearch}
      handleClear={() => {
        setSearchTerm('');
        setLocalSelectedStates([]);
      }}
      handleApply={() => {
        setSearchTerm('');
        setSelectedStates(localSelectedStates);
      }}
      handleClose={handleClose}>
      <SInfiniteScroll
        list={states}
        keyGetter={state => `${state.State}-${state.Country}`}
        fetchMore={fetchMore}
        render={state => (
          <ListItem
            label={state.State}
            searchTerm={searchTerm}
            isSelected={localSelectedStates.some(i => i.State === state.State)}
            onSelect={checked => handleSelect(checked, state)}
          />
        )}
      />
      {isLoading && <Loader />}
      {!isLoading && states?.length === 0 && (
        <DrawerListState title="No States Found" />
      )}
    </FilterContainer>
  );
};
