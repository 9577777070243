import { withStyles, Box } from '@material-ui/core';

export const DrawerDialog = withStyles({
  root: {
    height: '70%',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: '#fff',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px'
  }
})(Box);

export const DrawerDialogContainer = withStyles({
  root: {
    position: 'absolute',
    height: '100vh',
    width: '100%',
    zIndex: 10,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  }
})(Box);
