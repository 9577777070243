import { Box, makeStyles } from '@material-ui/core';
import {
  SAvatar,
  SCheckbox,
  SCheckboxProps,
  SText
} from '@setvi/shared/components';
import clsx from 'clsx';
import { memo } from 'react';
import { distances } from '../../../../styles';

const useContactStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    padding: distances.small,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)'
    },
    alignItems: 'center'
  },
  avatar: {
    marginRight: distances.small
  },
  details: { flexGrow: 1 },
  primaryText: {
    fontSize: 16
  },
  secondaryText: {
    fontSize: 12,
    color: '#92929D'
  },
  bold: {
    fontWeight: 500,
    color: '#44444F'
  }
}));

export type TContact = {
  Id?: number;
  Email: string;
  FirstName?: string;
  LastName?: string;
  Title?: string;
  CompanyName?: string;
  Type?: number;
  CRMWhoId?: string;
  CRMWhatId?: string;
  CustomerNumber?: string;
};

export interface ContactProps {
  contact: TContact;
  checkbox: SCheckboxProps;
}

export const Contact = memo(({ contact, checkbox }: ContactProps) => {
  const styles = useContactStyles();

  return (
    <Box className={styles.container}>
      <SAvatar
        className={styles.avatar}
        value={
          contact.FirstName && contact.LastName
            ? `${contact.FirstName.charAt(0)}${contact.LastName.charAt(0)}`
            : `${contact.Email?.slice(0, 2)}`
        }
      />
      <Box className={styles.details}>
        {(contact.FirstName || contact.LastName) && (
          <SText
            title={`${contact.FirstName ? contact.FirstName : ''} ${
              contact.LastName ? contact.LastName : ''
            }`}
          />
        )}
        <SText
          className={clsx({
            [styles.secondaryText]: contact.FirstName || contact.LastName,
            [styles.bold]: contact.FirstName || contact.LastName
          })}
          title={contact.Email}
        />
        {contact.CompanyName && (
          <SText
            className={styles.secondaryText}
            title={`${contact.CompanyName} ${contact.CustomerNumber ? `(${contact.CustomerNumber})` : ''}`}
          />
        )}
        {contact.Title && (
          <SText className={styles.secondaryText} title={contact.Title} />
        )}
      </Box>
      <Box>
        <SCheckbox {...checkbox} />
      </Box>
    </Box>
  );
});
