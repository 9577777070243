import { useMemo } from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Header } from '@setvi/shared/components/sdrawer-content/components/header';
import { DrawerContent } from '@setvi/shared/enums/common';
import SButton from '@setvi/shared/components/sbutton';
import { distances } from '../../../../styles';
import {
  NavigationButton,
  NavigationButtonProps
} from '../main-navigation/button';
import { useListStyles } from '../main-navigation/style';
import {
  FillCrmLeadIcon,
  FillCrmAccountIcon,
  FillRecentContactIcon,
  FillUserIcon
} from '../../../sicons';
import {
  CompanyModule,
  CompanyModuleId
} from '../../../../services/react-query/query/user/types';

export type SalesforceUser = {
  SalesforceEmail: string;
  SalesforceUserID: string;
};

const useContactsStyles = makeStyles(() => ({
  description: {
    color: '#919294',
    fontSize: 12
  },
  container: {
    padding: `${distances.xlarge}px ${distances.medium}px ${distances.medium}px ${distances.medium}px`
  },
  listContainer: {
    marginTop: distances.large
  },
  divider: { marginTop: distances.large },
  button: {
    marginTop: distances.large
  }
}));

interface ContactsProps {
  salesforceUser?: SalesforceUser;
  companyModules: CompanyModule[];
  setShowContent: (content: DrawerContent | null) => void;
  handleClose: () => void;
}

export const ContactsNavigation = ({
  salesforceUser,
  companyModules,
  handleClose,
  setShowContent
}: ContactsProps) => {
  const styles = useContactsStyles();
  const classes = useListStyles();

  const hubspotEnabled = companyModules.some(
    module => module.ModuleID === CompanyModuleId.HubSpot
  );

  const contactsNavigation: NavigationButtonProps[] = useMemo(
    () => [
      ...(salesforceUser?.SalesforceEmail &&
      salesforceUser?.SalesforceUserID &&
      !hubspotEnabled
        ? [
            {
              label: 'CRM Leads',
              icon: <FillCrmLeadIcon />,
              onClick: () => setShowContent(DrawerContent.crmLeads)
            },
            {
              label: 'CRM Contacts',
              icon: <FillCrmAccountIcon />,
              onClick: () => setShowContent(DrawerContent.crmAccounts)
            }
          ]
        : []),
      ...(salesforceUser?.SalesforceEmail &&
      salesforceUser?.SalesforceUserID &&
      hubspotEnabled
        ? [
            {
              label: 'CRM Contacts',
              icon: <FillCrmAccountIcon />,
              onClick: () => setShowContent(DrawerContent.hubspotContacts)
            }
          ]
        : []),
      {
        label: 'Recent Contacts',
        icon: <FillRecentContactIcon />,
        onClick: () => setShowContent(DrawerContent.recentContacts)
      },
      {
        label: 'Contacts',
        icon: <FillUserIcon />,
        onClick: () => setShowContent(DrawerContent.contacts)
      },
      {
        label: 'Groups',
        icon: <FillRecentContactIcon />,
        onClick: () => setShowContent(DrawerContent.groups)
      }
    ],
    [salesforceUser, setShowContent, hubspotEnabled]
  );

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="Contacts" handleClose={handleClose} />
      <Box className={styles.container}>
        <Typography className={styles.description}>
          Please click the button below to insert recipients.
        </Typography>
        <Grid container spacing={2} className={styles.listContainer}>
          {contactsNavigation.map((el, _index, _array) => (
            <Grid
              key={el.label}
              className={classes.grid}
              item
              //   To add setvi contacts on new line xs={index + 1 === array.length ? 12 : 6}
              xs={6}>
              <NavigationButton
                label={el.label}
                icon={el.icon}
                onClick={el.onClick}
              />
            </Grid>
          ))}
        </Grid>
        <Divider className={styles.divider} />
        <SButton
          className={styles.button}
          onClick={() => handleClose()}
          fullWidth
          color="default"
          variant="outlined">
          Cancel
        </SButton>
      </Box>
    </Box>
  );
};
