import { Avatar, AvatarProps } from '@material-ui/core';
import clsx from 'clsx';
import { useAvatarClasses } from './styles';

const stc = require('string-to-color');

export interface SAvatarProps extends AvatarProps {
  value: string;
  className?: string;
  size?: AvatarProps['sizes'];
}

export const SAvatar = ({
  className,
  value,
  size = 'md',
  src,
  ...rest
}: SAvatarProps) => {
  const classes = useAvatarClasses();

  return (
    <Avatar
      style={{ backgroundColor: src ? 'unset' : stc(value), ...rest.style }}
      className={clsx(classes.root, [classes[size]], className)}
      src={src}
      {...rest}>
      {value?.toLocaleUpperCase()}
    </Avatar>
  );
};
