import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SAvatar, SETVIImage, SText } from '@setvi/shared/components';
import { getInitials } from '@setvi/shared/utils';
import { useAppContext } from 'Providers/AppProvider/AppContext';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 40,
    margin: theme.spacing(2, 0)
  },
  profileName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

const Content = () => {
  const { user } = useAppContext();
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box mr={2}>
        {user?.Photo ? (
          <SETVIImage
            src={user.Photo}
            alt={user.FirstName}
            width={40}
            height={40}
            radius="50%"
          />
        ) : (
          <SAvatar
            alt={user.Email}
            style={{ width: 40, height: 40 }}
            value={getInitials({
              firstName: user.FirstName,
              lastName: user.LastName,
              email: user.Email
            })}
          />
        )}
      </Box>
      <SText weight="medium">
        {user?.FirstName} {user?.LastName}
      </SText>
    </Box>
  );
};

export default Content;
