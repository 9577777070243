export enum KeyCode {
  Z = 90,
  Y = 89,
  X = 88,
  P = 80,
  V = 86,
  BACKSPACE = 8,
  DELETE = 46
}

export enum EditorActions {
  VIEW = 'view',
  EDIT = 'edit',
  DELETE = 'delete',
  REMOVE = 'remove',
  INSERT = 'insert',
  UPDATE = 'update'
}
