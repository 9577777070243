import { SRouteGuard } from '@setvi/shared/components';
import { CompanyModuleId } from '@setvi/shared/services/react-query/query/user/types';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { RealmProvider } from 'Providers/MongoRealmProvider';
import { useMemo } from 'react';

interface ProtectedProductsProps {
  children: React.ReactNode;
}

const ProtectedProducts = ({ children }: ProtectedProductsProps) => {
  const { companyModules } = useAppContext();
  const isProductsModuleEnabled = useMemo(
    () =>
      companyModules.some(
        module => module.ModuleID === CompanyModuleId.Products
      ),
    [companyModules]
  );

  return (
    <SRouteGuard routeEnabled={isProductsModuleEnabled}>
      <RealmProvider>{children}</RealmProvider>
    </SRouteGuard>
  );
};

export default ProtectedProducts;
