import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, withStyles } from '@material-ui/core';

import SText from '../../../sui/stext';
import { passwordValidation } from '../../utils';

export enum PasswordStrength {
  VERY_WEAK = 'Very Weak',
  WEAK = 'Weak',
  GOOD = 'Strong',
  STRONG = 'Very Strong',
  DEFAULT = 'None'
}

export enum PasswordStrengthColor {
  VERY_WEAK = '#fbc37f',
  WEAK = '#FF9F29',
  GOOD = ' #6ee0a9',
  STRONG = '#23A566',
  DEFAULT = '#C4CFE1'
}

const Indicator = withStyles(() => ({
  root: {
    width: 50,
    height: 6,
    gridGap: 8,
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center'
  }
}))(Box);

const SPasswordIndicator = ({ password }: { password: string }) => {
  const passwordStrength = useMemo(
    () => passwordValidation(password),
    [password]
  );

  const color = Object.values(PasswordStrengthColor)[passwordStrength - 1];
  const strength = Object.values(PasswordStrength)[passwordStrength - 1];

  return (
    <Box
      mt={3}
      display="flex"
      alignItems="center"
      justifyContent="space-between">
      <Box display="flex" alignItems="center" gridGap={18}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Indicator
            key={`indicator-${uuidv4()}`}
            bgcolor={
              password && passwordStrength > index
                ? color
                : PasswordStrengthColor.DEFAULT
            }
          />
        ))}
      </Box>

      <Box>
        <SText noLineClamp fontColor="gray" weight="medium" size="sm">
          Strength :{' '}
          <span
            style={{
              color:
                password && passwordStrength > 0
                  ? color
                  : PasswordStrengthColor.DEFAULT
            }}>
            {password && passwordStrength > 0
              ? strength
              : PasswordStrength.DEFAULT}
          </span>
        </SText>
      </Box>
    </Box>
  );
};

export default SPasswordIndicator;
