import { Grid, Box, makeStyles, Typography } from '@material-ui/core';
import { Outlet } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import { SETVIImage } from '@setvi/shared/components';
import { setviTheme } from 'Styles/companyTheme';

const useSetviGuestStyles = makeStyles(() => ({
  container: {
    height: '100vh',
    overflow: 'auto'
  },
  illustrationSection: {
    backgroundColor: '#2196F3',
    display: 'flex',
    justifyContent: 'center'
  },
  illustrationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '80%'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  image: { maxWidth: '100%' },
  title: {
    color: '#fff',
    fontWeight: 700,
    margin: '30px 0 100px 0'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }
}));

export const SetviGuest = ({ children }: any) => {
  const classes = useSetviGuestStyles();

  const renderOption = children || <Outlet />;

  return (
    <ThemeProvider theme={setviTheme}>
      <Grid container className={classes.container}>
        <Grid item xs={6} className={classes.illustrationSection}>
          <Box className={classes.illustrationContainer}>
            <Box>
              <SETVIImage src="images/setvi-logo-white.png" alt="Setvi" />
            </Box>
            <Typography variant="h3" className={classes.title}>
              AI-Powered Revenue Acceleration Platform for Distributors and
              Manufacturers.
            </Typography>
            <Box className={classes.imageContainer}>
              <SETVIImage
                className={classes.image}
                src="images/setvi-welcome.svg"
                alt="Illustration"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.content}>{renderOption}</Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
