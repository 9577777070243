// eslint-disable-next-line import/no-extraneous-dependencies
import { datadogRum } from '@datadog/browser-rum';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { useEffect } from 'react';

datadogRum.init({
  applicationId: process.env.DATA_DOG_APPLICATION_ID,
  clientToken: process.env.DATA_DOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'webapp',
  env: process.env.NODE_ENV,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow'
});

interface DataDogProviderProps {
  children: React.ReactNode;
}

export const DataDogProvider = ({ children }: DataDogProviderProps) => {
  const { user } = useAppContext();

  useEffect(() => {
    if (user) {
      datadogRum.setUser({
        id: user.ID.toString(),
        email: user.Email,
        name: user.FullName
      });
    }
  }, [user]);

  return <>{children}</>;
};
