import clsx from 'clsx';
import { lazy, memo, ReactNode, Suspense, useEffect } from 'react';
import { VirtualItem } from '@tanstack/react-virtual';
import { Table, TableBody, TableRow, TableRowProps } from '@material-ui/core';
import { useInView } from 'react-intersection-observer';
import { SPaginatedTableProps } from '../../paginated';
import { useVirtualTableStyles } from './style';
import TableHeader, {
  TableCheckboxStyle,
  TableHeaderProps
} from '../../components/table/header';
import { BROWSERS, useBrowser } from '../../../../hooks';
import { SInfiniteScrollProps } from '../../../sinfinite-scroll';
import { SCheckbox, SCheckboxProps } from '../../../scheckbox';
import TableHeaderCell from '../../styles/TableHeaderCell';

const LazyTableBodyCell = lazy(
  () => import('@setvi/shared/components/s-table/components/table/cells')
);

interface VirtualRow<T> extends Omit<TableRowProps, 'onClick'> {
  rowStyleGetter?: (row: T, index?: number) => React.CSSProperties;
  checkbox?: (row: T) => SCheckboxProps;
  onClick?: (row: T) => void;
  fallback?: ReactNode;
}

interface SVirtualInfiniteScrollProps<T>
  extends Omit<
    SPaginatedTableProps<T>,
    'pagination' | 'showPagination' | 'customEmptyState' | 'tableStyle' | 'row'
  > {
  id?: string;
  header?: Omit<Partial<TableHeaderProps>, 'checkboxFreeSpace'>;
  vList: VirtualItem<any>[];
  height: number;
  infiniteScroll?: Omit<
    SInfiniteScrollProps<T>,
    | 'containerComponent'
    | 'wrapperComponent'
    | 'onWrapperClick'
    | 'list'
    | 'keyGetter'
    | 'render'
    | 'children'
  >;
  row?: VirtualRow<T>;
  options?: Record<string, string | number>;
}

const VirtualTable = <T,>({
  id,
  list,
  row = {},
  vList,
  header,
  height,
  options,
  columns,
  isLoading,
  searchQuery,
  stickyHeader,
  infiniteScroll = {},
  keyGetter
}: SVirtualInfiniteScrollProps<T>) => {
  const { browser } = useBrowser();
  const isSafari = browser === BROWSERS.safari;
  const {
    wrapperClass,
    containerClass,
    options: intersectionOptions,
    fetchMore,
    onWrapperDrop,
    onWrapperDrag,
    renderDragItem
  } = infiniteScroll;
  const { checkbox, onClick, fallback, rowStyleGetter } = row;
  const classes = useVirtualTableStyles({
    wrapperClick: !!onClick,
    height,
    isSafari
  });
  const { ref, inView } = useInView(intersectionOptions);

  useEffect(() => {
    if (inView) {
      fetchMore?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <Table stickyHeader={stickyHeader} className={classes.table}>
      <TableHeader
        columns={header?.columns || columns}
        checkboxFreeSpace={!header?.checkbox && !!row?.checkbox}
        {...header}
      />
      <TableBody id={id} className={clsx(classes.tbody, containerClass)}>
        {vList.map((virtualRow, index) => {
          const isLoaderRow = virtualRow.index > list?.length - 1;
          const element = list[virtualRow.index];

          if (!element) return null;

          return (
            <TableRow
              key={keyGetter(element)}
              onClick={() => onClick?.(element)}
              draggable={!!onWrapperDrag}
              onDragStart={() => onWrapperDrag?.(element)}
              className={clsx(classes.wrapper, wrapperClass)}
              onDragOver={e => e.preventDefault()}
              onDragStartCapture={e => {
                const div = renderDragItem?.(element);
                if (div) e.dataTransfer.setDragImage(div, 0, 0);
              }}
              onDrop={ev => {
                ev.preventDefault();
                ev.stopPropagation();
                onWrapperDrop?.(element);
              }}
              style={{
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start - ((options?.scrollMargin as number) || 0)}px) translateX(0px)`
              }}
              ref={virtualRow?.index + 1 === list?.length ? ref : null}>
              {isLoaderRow ? (
                'Loading more...'
              ) : (
                <>
                  {checkbox && (
                    <TableHeaderCell
                      style={{
                        ...TableCheckboxStyle,
                        ...rowStyleGetter?.(element, index)
                      }}
                      onClick={(e: any) => e.stopPropagation()}>
                      <SCheckbox
                        onChange={e => {
                          e.stopPropagation();
                          checkbox(element).onChange?.(e, e?.target?.checked);
                        }}
                        {...checkbox(element)}
                      />
                    </TableHeaderCell>
                  )}
                  <Suspense fallback={fallback}>
                    <LazyTableBodyCell
                      rowData={element}
                      columns={columns}
                      loading={isLoading}
                      searchQuery={searchQuery}
                      style={{ ...rowStyleGetter?.(element, index) }}
                    />
                  </Suspense>
                </>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const SVirtualTable = memo(VirtualTable) as typeof VirtualTable;
export default SVirtualTable;
