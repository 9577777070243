import {
  CSSProperties,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  withStyles,
  OutlinedInputProps
} from '@material-ui/core';
import { debounce } from 'lodash';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';

import { SearchIcon as Search } from '../../sicons/regular-icons';
import { SearchField, useStyles } from './style';
import { TextColor } from '../../../enums';

enum IconPosition {
  start = 'start',
  end = 'end'
}

const iconColor = TextColor.Grey;

const Loader = withStyles(({ spacing }) => ({
  root: {
    marginRight: spacing(1),
    color: iconColor
  }
}))(CircularProgress);

export interface SSearchInputProps
  extends Omit<OutlinedInputProps, 'onChange'> {
  placeholder?: string;
  loading?: boolean;
  width?: number | string;
  height?: number | string;
  searchedValue?: string;
  defaultValue?: string;
  className?: string;
  style?: CSSProperties;
  onChange(val: string): void;
  iconPosition?: keyof typeof IconPosition;
}

export const SSearchInput = forwardRef(
  (
    {
      placeholder = 'Search...',
      width = 320,
      height,
      loading = false,
      searchedValue,
      defaultValue,
      className,
      style,
      iconPosition = IconPosition.end,
      onChange,
      ...rest
    }: SSearchInputProps,
    ref
  ) => {
    const [value, setValue] = useState(defaultValue || '');

    const classes = useStyles({ width, height });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChange = useCallback(
      debounce((val: string) => onChange(val), 500),
      [onChange]
    );

    const handleInputClear = useCallback(() => {
      setValue('');
      onChange('');
      handleChange.cancel();
    }, [handleChange, onChange]);

    const endIcon = useMemo(() => {
      if (loading) return <Loader size={18} />;
      if (iconPosition === IconPosition.end && !value)
        return <Search style={{ fontSize: '1.15rem' }} />;
      if (value)
        return (
          <IconButton size="small" onClick={handleInputClear}>
            <Close fontSize="small" style={{ color: iconColor }} />
          </IconButton>
        );
      return null;
    }, [handleInputClear, iconPosition, loading, value]);

    useEffect(
      () => {
        if (searchedValue !== undefined && value !== searchedValue) {
          // ! This line gives bug when use searchedValue and onChange in the same time
          // handleChange(searchedValue);
          setValue(searchedValue);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [searchedValue]
    );

    // This is used for clearing the search field from the parent component
    useImperativeHandle(ref, () => ({
      clear: () => setValue('')
    }));

    return (
      <SearchField
        placeholder={placeholder}
        className={clsx(classes.wrapper, className)}
        style={style}
        onChange={e => {
          handleChange(e.target.value);
          setValue(e.target.value);
        }}
        value={value}
        fullWidth
        startAdornment={
          iconPosition === IconPosition.start && (
            <InputAdornment position="start">
              <Search fontSize="small" />
            </InputAdornment>
          )
        }
        endAdornment={<InputAdornment position="end">{endIcon}</InputAdornment>}
        {...rest}
      />
    );
  }
);
