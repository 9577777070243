import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSalesforceStates } from '../../../../../../services';

export const useStates = () => {
  const [stateSearchTerm, setStateSearchTerm] = useState<string>('');

  const {
    data: stateData,
    isLoading: isStateLoading,
    isFetching: isStateFetching
  } = useQuery(getSalesforceStates());

  return {
    states:
      stateData?.Data?.filter(
        state =>
          state.State?.toLowerCase().includes(stateSearchTerm.toLowerCase()) ||
          state.StateCode?.toLowerCase().includes(stateSearchTerm.toLowerCase())
      ) || [],
    statesLoading: isStateLoading || isStateFetching,
    stateSearchTerm,
    setStateSearchTerm
  };
};
