import { useCallback, useMemo, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { State } from '../../../../../../services/react-query/query/contacts/types';
import { getSalesforceCities } from '../../../../../../services';

const CITIES_LIMIT = 30;

interface useCitiesProps {
  selectedStateCodes: State['StateCode'][];
}

export const useCities = ({ selectedStateCodes }: useCitiesProps) => {
  const [searchString, setSearchString] = useState<string>('');

  const cityQueryVariables = useMemo(
    () => ({
      pageNumber: 1,
      pageSize: CITIES_LIMIT,
      states: selectedStateCodes,
      searchString
    }),
    [selectedStateCodes, searchString]
  );

  const {
    data: citiesData,
    isLoading: citiesLoading,
    isFetching: citiesFetching,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(getSalesforceCities(cityQueryVariables));

  const fetchMoreCities = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return {
    cities: citiesData?.pages?.flatMap(page => page?.Data) || [],
    citiesLoading: citiesLoading && citiesFetching,
    searchString,
    setSearchString,
    fetchMoreCities
  };
};
