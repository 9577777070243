import { TContact } from '@setvi/shared/components/sdrawer-content/components/contact';
import { Box } from '@material-ui/core';
import { ContactsLoader } from '@setvi/shared/components/sdrawer-content/components/contact/loader';
import { DrawerContent } from '../../../../enums';
import { useRecentContacts } from './useRecentContacts';
import { Header } from '../../components/header';
import { Subheader } from '../../components/subheader';
import { SSearchInput } from '../../../sforms';
import { AddressBook } from '../../components/contact/address-book';
import { SEmptyState } from '../../..';
import { ContactFilter } from '../../components/contact/contact-filter';
import { Recipient } from '../../../../interfaces';

interface RecentContactsProps {
  selectedContacts?: Recipient[];
  modifyRecipientList: (contact: TContact) => void;
  setShowContent: (content: DrawerContent | null) => void;
  handleClose: () => void;
}

export const RecentContacts = ({
  selectedContacts,
  modifyRecipientList,
  setShowContent,
  handleClose
}: RecentContactsProps) => {
  const {
    setSearchString,
    sortBy,
    setSortBy,
    recentContacts,
    isLoading,
    fetchMoreRecentContacts
  } = useRecentContacts();

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="Recipients" handleClose={handleClose} />
      <Subheader
        handleBack={() => setShowContent(DrawerContent.contactsNavigation)}
        text="Recent Contacts"
      />
      <Box p={2} mb={1} display="flex">
        <SSearchInput onChange={value => setSearchString(value)} />
        <ContactFilter sortBy={sortBy} setSortBy={setSortBy} />
      </Box>
      <Box overflow="auto">
        <AddressBook
          contacts={recentContacts}
          selectedContacts={selectedContacts}
          fetchMore={fetchMoreRecentContacts}
          onContactClick={modifyRecipientList}
          sortingParameter={sortBy}>
          <>
            {isLoading && <ContactsLoader />}

            {!isLoading && recentContacts?.length === 0 && (
              <SEmptyState
                imageSrc="/images/no-recent-contacts.png"
                subTitle="No Recent Contacts"
              />
            )}
          </>
        </AddressBook>
      </Box>
    </Box>
  );
};
