import { useRef, useState } from 'react';
import {
  SButton,
  SInfiniteScroll,
  SSearchInput,
  SText
} from '@setvi/shared/components';
import { Box, Divider, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import {
  Account as AccountType,
  State,
  City
} from '../../../../../../services/react-query/query/contacts/types';
import { DrawerListState } from '../../../../components/list-state';
import { useAccounts } from '../useAccounts';
import { Account } from './account';

interface AccountSelectProps {
  selectedCities: City[];
  selectedStateCodes: State['StateCode'][];
  selectedAccounts: AccountType[];
  setSelectedAccounts: (accounts: AccountType[]) => void;
  handleClose: () => void;
}

export const AccountSelect = ({
  selectedCities = [],
  selectedStateCodes = [],
  selectedAccounts = [],
  setSelectedAccounts,
  handleClose
}: AccountSelectProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [selAccounts, setSelAccounts] =
    useState<AccountType[]>(selectedAccounts);

  const {
    accounts,
    isLoading: accountsLoading,
    setSearchTerm,
    searchTerm,
    fetchMoreAccounts
  } = useAccounts({
    cities: selectedCities,
    states: selectedStateCodes
  });

  const hnaldeSelect = (checked: boolean, account: AccountType) => {
    if (checked) {
      setSelAccounts(prevAccounts => [...prevAccounts, account]);
    } else {
      setSelAccounts(selAccounts.filter(acc => acc.Id !== account.Id));
    }
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" minHeight={0}>
      <Box display="flex" alignItems="center" m={3} mb={2}>
        <SText title="Filter By Accounts" weight="bold" />
        <Box ml={2}>
          <SButton
            variant="text"
            onClick={() => {
              // @ts-ignore
              ref?.current?.clear();
              setSearchTerm('');
              setSelAccounts([]);
            }}
            style={{ padding: 0, fontWeight: 400, height: 20 }}>
            Clear all
          </SButton>
        </Box>
        <Box ml="auto">
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Divider />
      </Box>
      <Box my={2} mx={3}>
        <SSearchInput
          ref={ref}
          defaultValue={searchTerm}
          onChange={setSearchTerm}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          marginLeft: 10,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Box>
          <SInfiniteScroll
            list={accounts}
            keyGetter={el => el.Id}
            fetchMore={fetchMoreAccounts}
            render={account => (
              <Account
                account={account}
                isSelected={selAccounts.some(acc => acc.Id === account.Id)}
                onSelect={hnaldeSelect}
              />
            )}
          />
        </Box>
        {accountsLoading && <DrawerListState title="Loading" />}
        {!accountsLoading && accounts?.length === 0 && (
          <DrawerListState title="No Accounts Found" />
        )}
      </Box>
      <Divider />
      <Box p={3}>
        <SButton
          fullWidth
          onClick={() => {
            setSelectedAccounts(selAccounts);
            // @ts-ignore
            ref?.current?.clear();
            setSearchTerm('');
          }}>
          Apply
        </SButton>
      </Box>
    </Box>
  );
};
