import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Editor as TinyMCEEditor, EditorOptions, EditorEvent } from 'tinymce';
import { Link } from '@setvi/shared/interfaces';
import {
  getPreviewLink,
  normalizeLinkToPreviewLink
} from '@setvi/shared/utils';
import { generateLinkViewerUrl } from '@setvi/shared/utils/viewer';
import { EditorActions, KeyCode } from '../../enums';

import {
  IEditorLink,
  SEditorType,
  IEditorHook,
  IHandleLinkOptions,
  PastePostProcessEvent
} from '../../interfaces';
import { viewIcon, editIcon, removeIcon } from '../../components';
import { selectedProductLinkClassName } from '../use-editor-products';

export const useEditorLinksHook = ({
  editorRef,
  importedLinks,
  getLinkAction,
  getLinks
}: IEditorHook) => {
  const [links, setLinks] = useState<IEditorLink[]>([]);
  const [currentId, setCurrentId] = useState(null);

  const [clean, setClean] = useState(false);

  // We have a lot links with old logic so this is backup to recognize old links and replace them with new ones
  useEffect(() => {
    if (editorRef && importedLinks?.length) {
      const content = editorRef?.getBody();
      const oldLinks = content?.querySelectorAll('a[href].immutableLink');

      if (oldLinks?.length) {
        oldLinks.forEach((aTag: HTMLElement) => {
          const currentLink = importedLinks?.find(
            elem =>
              elem?.Placeholder === aTag.dataset.id ||
              elem?.Placeholder === aTag.getAttribute('href')
          );

          const normalizedLink = normalizeLinkToPreviewLink(currentLink);
          const href = generateLinkViewerUrl(normalizedLink);

          const placeholder = currentLink?.Placeholder;

          aTag.setAttribute('id', placeholder); // Change 'newIdValue' to the new id value
          aTag.setAttribute('href', href); // Change 'newHrefValue' to the new href value
          aTag.classList.remove('immutableLink'); // Add a new class or modify existing classes
        });
      }

      const bodyLinks = content?.querySelectorAll('a[href][id]');
      const arrayFromNodeList = Array.from(bodyLinks);

      const filteredLinks = importedLinks
        ?.filter(link => !links?.find(e => e.Placeholder === link.Placeholder))
        ?.map(link => ({
          ...link,
          vissible: true
        }));

      filteredLinks?.forEach(async link => {
        if (arrayFromNodeList.find(e => e.id === link.Placeholder)) return;

        const previewHref = await generateLinkViewerUrl(getPreviewLink(link));

        await editorRef.execCommand(
          'mceInsertContent',
          false,
          `<a id=${link?.Placeholder} href=${link?.Placeholder} target="_blank" rel="noreferrer noopener" data-preview-href=${previewHref}>${link?.Name}</a> `
        );
      });

      setLinks(prev => {
        const filtered = prev.filter(
          link => !filteredLinks.find(e => e.Placeholder === link.Placeholder)
        );

        return [...filtered, ...filteredLinks];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef, importedLinks]);

  useEffect(() => {
    if (currentId) {
      setLinks(prev =>
        prev.map(link => ({
          ...link,
          vissible: currentId === link.Placeholder ? false : link?.vissible
        }))
      );
      getLinks?.(links.filter(link => currentId !== link.Placeholder));
      setCurrentId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentId]);

  useEffect(() => {
    if (!editorRef || !clean) return;

    const aTagsToRemove = editorRef
      ?.getBody()
      ?.querySelectorAll('a:only-child > br[data-mce-bogus="1"]');

    const hideLinksId: string[] = [];

    // Remove each found <a> tag
    aTagsToRemove.forEach(brElement => {
      const aTag = brElement.parentNode; // Get the parent <a> element
      if ((aTag as Element)?.id) hideLinksId.push((aTag as Element).id);
      aTag.parentNode.removeChild(aTag); // Remove the <a> element
    });

    setLinks(prev =>
      prev.map(link => ({
        ...link,
        vissible: hideLinksId.includes(link?.Placeholder)
          ? false
          : link?.vissible
      }))
    );

    setClean(false);
  }, [editorRef, clean]);

  const editLink = (editorNode: TinyMCEEditor) => {
    const selection = editorRef?.selection || editorNode?.selection;
    const linkNode = selection?.getNode();
    const Id = linkNode?.id || linkNode?.getAttribute('href');

    getLinkAction?.({
      action: EditorActions.EDIT,
      id: Id
    });
  };

  const deleteLink = (
    editorNode: TinyMCEEditor,
    preventDefault?: () => void
  ) => {
    const editor = editorRef || editorNode;
    const selection = editor?.selection;
    const selectedNode = selection?.getNode();

    const highlitedContent = selection?.getContent({ format: 'html' });

    if (highlitedContent) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = highlitedContent;
      const aElements = tempDiv.querySelectorAll('a[href]');

      const deletedLinks: string[] = [];
      aElements.forEach(a => {
        deletedLinks.push(a?.id);
      });

      setLinks(prev =>
        prev.map(link => ({
          ...link,
          vissible: deletedLinks.includes(link?.Placeholder)
            ? false
            : link?.vissible
        }))
      );
    }

    if (selectedNode?.tagName === 'A') {
      // If the selected node is an <a> tag, delete only the selected node
      const range = selection.getRng();
      range.setStartBefore(selectedNode);
      range.setEndAfter(selectedNode);
      selection.setRng(range);

      setCurrentId(selectedNode?.id);

      editor.execCommand('Delete');
      setLinks(prev =>
        prev.map(link => ({
          ...link,
          vissible:
            link?.Placeholder === selectedNode?.id ? false : link?.vissible
        }))
      );

      preventDefault?.();
    }
  };

  const viewLink = (editorNode: TinyMCEEditor) => {
    const selection = editorRef?.selection || editorNode?.selection;

    const linkNode = selection?.getNode();

    const link =
      linkNode?.getAttribute('data-preview-href') ||
      linkNode?.getAttribute('href');

    window.open(link, '_blank').focus();
  };

  const handleCursorIsInsideLink = (editor: SEditorType) => {
    const bookmark = editor.selection.getBookmark();
    editor.selection.moveToBookmark(bookmark);

    // Check if the cursor is inside a link
    const node = editor.selection.getNode();

    if (node.nodeName === 'A' && node?.hasAttribute('id')) {
      const range = editor.selection.getRng();
      // Check if cursor is at the beginning  of the link
      const start = range.startOffset === 1 && range.endOffset === 1;
      if (start) {
        // Move the cursor to the beginning  of the link
        const newText = editor.getDoc().createTextNode(' ');
        node.parentNode.insertBefore(newText, node);
        range.setStartBefore(newText);
        range.setEndBefore(node);
        editor.selection.setRng(range);
      } else {
        // Move the cursor to the  ending of the link
        range.setEndAfter(node);
        range.collapse(false);
        editor.selection.setRng(range);
        editor.execCommand('mceInsertContent', false, '');
      }
    }
  };

  const importPastedLinks = (
    e: EditorEvent<PastePostProcessEvent>,
    editor: TinyMCEEditor
  ) => {
    const pastedLinksNodes = e?.node?.querySelectorAll(
      'a[href][id][data-preview-href]'
    );
    const pastedLinks = Array.from(pastedLinksNodes);

    if (!pastedLinks?.length) return;
    e.preventDefault();

    const paragraph = e?.node?.querySelectorAll('p');

    setLinks(prev => {
      const newLinks: IEditorLink[] = [];

      if (paragraph?.length) {
        paragraph.forEach(p => {
          const paragraphLinks = p?.querySelectorAll('a[href][id]');
          const paragraphLinksArray = Array.from(paragraphLinks);

          paragraphLinksArray.forEach(link => {
            const findLink = prev.findIndex(l => l.Placeholder === link?.id);

            const placeholder = uuidv4();
            link.setAttribute('id', placeholder);
            link.setAttribute('href', placeholder);

            newLinks.push({
              ...prev[findLink],
              vissible: true,
              Placeholder: placeholder
            });
          });
          editor?.execCommand('mceInsertContent', false, p?.innerHTML);
          editor?.execCommand('mceInsertNewLine');
        });
      } else {
        pastedLinks.forEach(link => {
          const findLink = prev.findIndex(l => l.Placeholder === link?.id);

          const placeholder = uuidv4();
          link.setAttribute('id', placeholder);
          link.setAttribute('href', placeholder);

          newLinks.push({
            ...prev[findLink],
            vissible: true,
            Placeholder: placeholder
          });
        });

        editor?.execCommand('mceInsertContent', false, e?.node?.innerHTML);
        editor?.execCommand('mceInsertNewLine');
      }

      const allLinks = [...prev, ...newLinks];
      // filter duplicates
      return allLinks.filter(
        (link, index, self) =>
          index === self.findIndex(l => l.Placeholder === link.Placeholder)
      );
    });
  };

  const verifyLinks = (editor: TinyMCEEditor) => {
    const editorLinks = editor?.getBody().querySelectorAll('a[href][id]');
    const existingLinks = Array.from(editorLinks);
    const special = '&#xFEFF;';

    const emptyLinksArray = existingLinks.filter(
      link => !link?.innerHTML?.trim() || link?.innerHTML === special
    );
    const fullLinksArray = existingLinks.filter(link =>
      link?.innerHTML?.trim()
    );

    if (emptyLinksArray?.length) {
      emptyLinksArray.forEach(link => {
        link.remove();
      });
    }

    setLinks(prev =>
      prev?.map(l => ({
        ...l,
        vissible: emptyLinksArray?.find(e => e?.id === l?.Placeholder)
          ? false
          : !!fullLinksArray?.find(e => e?.id === l?.Placeholder)
      }))
    );

    editorRef?.execCommand('InsertNewBlockAfter');
    editorRef?.execCommand('mceCleanup');
  };

  const handleXYZ = (editorNode: TinyMCEEditor) => {
    const editorLinks = editorNode?.getBody().querySelectorAll('a[href][id]');
    const existingLinks = Array.from(editorLinks);

    // check if cursor is inside a tag
    handleCursorIsInsideLink(editorNode);

    setLinks(prev =>
      prev.map(link => ({
        ...link,
        vissible: !!existingLinks?.find(e => e?.id === link?.Placeholder)
      }))
    );

    editorNode?.execCommand('mceInsertContent', false, '');
    setClean(true);
    verifyLinks(editorNode);
  };

  // Remove whole link for backspace and delete key
  const handleKeyDown = async (
    e: {
      keyCode: number;
      ctrlKey: boolean;
      metaKey: boolean;
      preventDefault: () => void;
    },
    editorNode: TinyMCEEditor
  ) => {
    if (e.keyCode === KeyCode.BACKSPACE || e.keyCode === KeyCode.DELETE) {
      await deleteLink(editorNode, e.preventDefault);
    } else if (
      (e.ctrlKey || e.metaKey) &&
      (e.keyCode === KeyCode.Z || e.keyCode === KeyCode.Y)
    ) {
      await handleXYZ(editorNode);
    } else {
      const ignoredKeyCodes = [37, 38, 39, 40]; //   Arrow keys
      if (e.ctrlKey || e.metaKey || ignoredKeyCodes.includes(e.keyCode)) return;
      await handleCursorIsInsideLink(editorNode);
    }
  };

  const insertLink = (link: Link) => {
    if (!link || !editorRef) return;

    const previewHref = generateLinkViewerUrl(getPreviewLink(link));

    const selection = editorRef?.selection;
    const selectedNode = selection?.getNode();

    if (selectedNode?.tagName === 'A') handleCursorIsInsideLink(editorRef);

    editorRef?.execCommand(
      'mceInsertContent',
      false,
      `<a id=${link?.Placeholder} href=${link?.Placeholder} target="_blank" rel="noreferrer noopener" data-preview-href=${previewHref}>${link?.Name}</a>`
    );

    setLinks(prev => [
      ...prev,
      {
        ...link,
        vissible: true
      }
    ]);
    if (getLinks) getLinks([...links, link]);
  };

  const updateLink = (link: Link, oldLink: Link) => {
    const node = editorRef?.dom
      .getRoot()
      .querySelector(`[id="${oldLink.Placeholder}"]`);

    if (!link || !editorRef || !node) return;

    const href = generateLinkViewerUrl(getPreviewLink(link));

    node.textContent = link?.Name;
    node.setAttribute('href', link?.Placeholder);
    node.setAttribute('data-preview-href', href);
    node.setAttribute('id', link?.Placeholder);

    const updatedLinks = links.map(prevLink =>
      prevLink?.Placeholder === oldLink?.Placeholder ? link : prevLink
    );
    setLinks(updatedLinks);

    // We need to regonize if link changed name or items length because of different actions in drawer (one needs to close current item other doesn't)
    getLinkAction?.({
      action:
        link?.Item?.Items?.length === oldLink?.Item?.Items?.length
          ? EditorActions.UPDATE
          : EditorActions.EDIT,
      id: link?.Placeholder
    });

    getLinks?.(updatedLinks);
    editorRef?.execCommand('mceInsertContent', false, '');
  };

  const removeLink = (removedLink: Link, editorNode: TinyMCEEditor) => {
    const editor = editorRef || editorNode;
    const body = editor?.getBody();
    const bodyLinks = body?.querySelectorAll('a[href][id]');

    const node = Array.from(bodyLinks)?.find(
      link => link?.id === removedLink?.Placeholder
    );

    if (!node) return;

    setLinks(prev =>
      prev.map(link => ({
        ...link,
        vissible:
          link?.Placeholder === removedLink?.Placeholder
            ? false
            : link?.vissible
      }))
    );

    node.remove();
    editorRef?.execCommand('mceInsertContent', false, '');
  };

  const handleSnippet = (value: string) => {
    if (!editorRef) return;

    editorRef?.execCommand('mceInsertContent', false, value);
  };

  const handleLinks = (
    action: EditorActions,
    { link, oldLink, editorNode }: IHandleLinkOptions
  ) => {
    switch (action) {
      case EditorActions.EDIT:
        editLink(editorNode);
        break;
      case EditorActions.DELETE:
        deleteLink(editorNode);
        break;
      case EditorActions.REMOVE:
        removeLink(link, editorNode);
        break;
      case EditorActions.INSERT:
        insertLink(link);
        break;
      case EditorActions.UPDATE:
        updateLink(link, oldLink);
        break;
      default:
        viewLink(editorNode);
    }
  };

  const initLinkOptions: Partial<Omit<EditorOptions, 'selector' | 'target'>> = {
    setup: (editor: TinyMCEEditor) => {
      editor.on('keydown', e => {
        handleKeyDown(e, editor);
      });

      editor.on('keyup', async e => {
        if ((e.metaKey || e.ctrlKey) && e.keyCode === KeyCode.X) {
          await handleXYZ(editor);
        }

        verifyLinks(editor);
      });

      editor.on('PastePostProcess', e => importPastedLinks(e, editor));

      editor.ui.registry.addContextToolbar('firstContextToolbar', {
        predicate: node =>
          node.nodeName === 'A' &&
          !node.hasAttribute('data-preview-href') &&
          !node?.classList.contains(selectedProductLinkClassName),
        items: 'viewlink',
        position: 'node',
        scope: 'node'
      });

      editor.ui.registry.addContextToolbar('secondContextToolbar', {
        predicate: node =>
          node.nodeName === 'A' &&
          node?.hasAttribute('id') &&
          node.hasAttribute('data-preview-href'),
        items: 'viewlink editlink deletelink',
        position: 'node',
        scope: 'node'
      });

      editor.ui.registry.addIcon('view', viewIcon);
      editor.ui.registry.addIcon('edit', editIcon);
      editor.ui.registry.addIcon('trash', removeIcon);

      editor.ui.registry.addButton('viewlink', {
        icon: 'view',
        text: 'View',
        tooltip: 'View Link',
        onAction: () =>
          handleLinks(EditorActions.VIEW, {
            editorNode: editor
          })
      });

      editor.ui.registry.addButton('editlink', {
        icon: 'edit',
        text: 'Edit',
        tooltip: 'Edit Link',
        onAction: () =>
          handleLinks(EditorActions.EDIT, {
            editorNode: editor
          })
      });

      editor.ui.registry.addButton('deletelink', {
        icon: 'trash',
        text: 'Delete',
        tooltip: 'Delete Link',
        onAction: () =>
          handleLinks(EditorActions.DELETE, {
            editorNode: editor
          })
      });
    }
  };

  return {
    links: !editorRef
      ? importedLinks
      : links?.filter(link => link?.vissible) || [],
    currentId,
    initLinkOptions,

    setLinks,
    handleLinks,
    setCurrentId,
    handleKeyDown,
    handleSnippet,
    importPastedLinks
  };
};
