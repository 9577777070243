import { BreadcrumbsProps, Breadcrumbs } from '@material-ui/core';
import { useBreadcrumbsClasses } from './styles';
import SText from '../sui/stext';

export interface SBreadcrumbProps<T> extends BreadcrumbsProps {
  items: Array<T>;
  parseBreadcrumb: (breadcrumb: T) => string;
  onBreadcrumbClick?: (breadcrumb: T) => void;
  fontWeight?: number;
  fontSize?: number;
}

const SBreadcrumbs = <T,>({
  items,
  parseBreadcrumb,
  onBreadcrumbClick,
  fontSize = 14,
  fontWeight = 500,
  ...rest
}: SBreadcrumbProps<T>) => {
  const classes = useBreadcrumbsClasses();

  return (
    <Breadcrumbs
      classes={classes}
      aria-label="breadcrumb"
      style={{
        fontSize,
        fontWeight
      }}
      {...rest}>
      {items?.map((item, idx) => {
        const lastItem = idx + 1 === items.length;
        const readOnly = !onBreadcrumbClick;

        return (
          <SText
            color={lastItem ? 'primary' : 'inherit'}
            onClick={(_event: React.MouseEvent<HTMLAnchorElement>) =>
              onBreadcrumbClick?.(item)
            }
            style={{
              fontSize,
              fontWeight,
              ...(readOnly
                ? {
                    cursor: 'auto',
                    textDecoration: lastItem ? 'underline' : 'none'
                  }
                : {})
            }}>
            {parseBreadcrumb(item)}
          </SText>
        );
      })}
    </Breadcrumbs>
  );
};

export default SBreadcrumbs;
