import { Box, IconButton, withStyles } from '@material-ui/core';

import { SText } from '@setvi/shared/components';

interface MinimazedProps {
  elements: {
    key: string;
    icon: JSX.Element;
    onClick?: () => void;
  }[];
  title: string;
  onWrapperClick?: () => void;
  display?: 'flex' | 'none';
}

const StyledBox = withStyles(({ palette, spacing }) => ({
  root: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    padding: spacing(2, 4),
    borderRadius: 'inherit',
    backgroundColor: '#171725',
    color: palette.common.white,
    justifyContent: 'space-between',

    '& button': {
      padding: 2,
      margin: 0
    }
  }
}))(Box);

const Minimazed = ({
  elements,
  title,
  onWrapperClick,
  display
}: MinimazedProps) => (
  <StyledBox
    display={display}
    onClick={() => onWrapperClick?.()}
    style={{ cursor: !!onWrapperClick && 'pointer' }}>
    <SText weight="semibold" fontColor="#fff" title={title}>
      {title}
    </SText>
    <Box display="flex" alignItems="center" gridGap={6}>
      {elements.map(({ icon, key, onClick }) => (
        <IconButton key={key} onClick={() => onClick?.()}>
          {icon}
        </IconButton>
      ))}
    </Box>
  </StyledBox>
);

export default Minimazed;
