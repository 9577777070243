import React, { useRef } from 'react';
import { Box, Divider, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { SButton, SSearchInput, SText } from '@setvi/shared/components';

interface FilterHeaderProps {
  children?: React.ReactNode;
  selected: boolean;
  filterBy: string;
  handleSearch: (searchTerm: string) => void;
  handleClear: () => void;
  handleClose: () => void;
  handleApply: () => void;
}

export const FilterContainer = ({
  children,
  selected,
  filterBy = '',
  handleSearch,
  handleClear,
  handleClose,
  handleApply
}: FilterHeaderProps) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <Box display="flex" flexDirection="column" height="100%" minHeight={0}>
      <Box display="flex" alignItems="center" m={3} mb={2}>
        <SText title={`Filter By ${filterBy}`} weight="bold" />
        {selected && (
          <Box ml={2}>
            <SButton
              variant="text"
              onClick={() => {
                // @ts-ignore
                ref?.current?.clear();
                handleClear();
              }}
              style={{ minHeight: 'unset', height: 20, fontWeight: 400 }}>
              Clear all
            </SButton>
          </Box>
        )}
        <Box ml="auto">
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Divider />
      </Box>
      <Box my={2} mx={3}>
        <SSearchInput ref={ref} onChange={handleSearch} />
      </Box>
      <Box
        sx={{
          flex: 1,
          padding: '0 8px',
          overflow: 'hidden auto',
          display: 'flex',
          flexDirection: 'column'
        }}>
        {children}
      </Box>
      <Divider />
      <Box p={3}>
        <SButton
          fullWidth
          onClick={() => {
            // @ts-ignore
            ref?.current?.clear();
            handleApply();
          }}>
          Apply
        </SButton>
      </Box>
    </Box>
  );
};
